import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa6_0 } from "../services/etapa1";
import 'firebase/storage';
import SignatureCanvas from 'react-signature-canvas';
import '../styles/styles.css'
import $ from 'jquery';





type NewPageProps = {
  WrappedComponent: React.ComponentType<any>;
}

const Etapas6: React.FC<NewPageProps> = ({ WrappedComponent }) => {
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

  const handleToggleMenu = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    window.location.href = '/';
  };


  return (
    <div>
      <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
        <Navbar.Brand>
          <img src={usuario} alt='User Logo' height='50' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse in={!menuCollapsed}>
          <Nav>
            <NavDropdown title="Usuario" id='user-dropdown'>
              <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div style={{ paddingTop: '80px' }}>
        <div className='container-fluid' style={{ height: '100vh' }}>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                <Etapa6 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );

};




const Etapa6: React.FC = () => {

  const [firmaOperadorUrl, setFirmaOperadorUrl] = useState<string | null>(null);
  const [firmaIntercambistaUrl, setFirmaIntercambistaUrl] = useState<string | null>(null);
  const firmaOperadorCanvasRef = useRef<SignatureCanvas | null>(null);
  const firmaIntercambistaCanvasRef = useRef<SignatureCanvas | null>(null);
  const [signatureConfirmed, setSignatureConfirmed] = useState(false);
  const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
    Intercambio_firmaIntercambistaUrl: null,
    Intercambio_firmaOperadorUrl: null,
  });
  
  const [intercambistaSignatureSaved, setIntercambistaSignatureSaved] = useState(false);
  const [operadorSignatureSaved, setOperadorSignatureSaved] = useState(false);
  
  const [imagesSelected,setImagesSelected] = useState(false);
  useEffect(() => {
    const allImagesSelected = Object.values(imagesData).every(image => image !==null);
    setImagesSelected(allImagesSelected);
  } , [imagesData]);

  const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      setImagesData((prevImagesData) => ({
        ...prevImagesData,
        [id]: files[0],
      }));
    }
  };

  const clearCanvas = (canvasRef: React.RefObject<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');
    if (!context) return;

    context.clearRect(0, 0, canvas.width, canvas.height);
  };

  const saveSignature = (canvasId: string, key: string) => {
    const canvas = document.getElementById(canvasId) as HTMLCanvasElement | null;
    if (!canvas) return;

    canvas.toBlob((blob) => {
      if (blob) {
        const imageFile = new File([blob], 'firma.png');
        setImagesData((prevImagesData) => ({
          ...prevImagesData,
          [key]: imageFile,
        }));
      }
    }, 'image/png');
  };


  const handleSaveOperadorSignature = () => {
    if (!operadorSignatureSaved) {
      saveSignature('firmaOperadorCanvas', 'Intercambio_firmaOperadorUrl');
      setOperadorSignatureSaved(true);
    }
  };

  const handleSaveIntercambistaSignature = () => {
    if (!intercambistaSignatureSaved) {
      saveSignature('firmaIntercambistaCanvas', 'Intercambio_firmaIntercambistaUrl');
      setIntercambistaSignatureSaved(true);
    }
  };

  const handleSiguienteClick = async () => {
    const filenames: { [key: string]: string | null } = {};
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? '';
    const estatus = 14;

    for (const key in imagesData) {
      if (imagesData.hasOwnProperty(key)) {
        const file = imagesData[key];
        if (file) {
          const filename = await uploadImageToFirebaseStorage(file);
          filenames[key] = filename;
        }
      }
    }

    try {
      const data = {
        firmaIntercambistaUrl: filenames.Intercambio_firmaIntercambistaUrl,
        firmaOperadorUrl: filenames.Intercambio_firmaOperadorUrl,
        estatus,
      };

      console.log(data);
      const response = await Etapa6_0(id, data);
      window.location.href = `/Home`;
    } catch (error: any) {
      console.error('Error en la api:', error.message);
    }
  };

  const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();

      const timestamp = Date.now();
      const randomNumber = Math.floor(Math.random() * 1000);
      const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

      const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

      imageRef
        .put(file)
        .then(() => {
          resolve(uniqueFilename);
        })
        .catch((error) => {
          console.error('Error al subir la imagen:', error);
          reject(error);
        });
    });
  };






  return (
    <div>
      <h1>Firmas</h1>
      <div>
        <h2>Operador</h2>
        <p className="text-muted">
          Por este medio declaro haber estado presente durante la inspeccion del equipo y avalo que las condiciones
          del mismo señaladas son correctas y verdaderas
        </p>
        <div className="contenedor-firma">
          <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 400, height: 200, id: "firmaOperadorCanvas" }}
            ref={firmaOperadorCanvasRef}
          />

          <input
            id="Intercambio_firmaOperadorUrl"
            name="Intercambio_firmaOperadorUrl"
            type="text"
            className="form-control"
            onChange={(e) => {
              handleImageChange('Intercambio_firmaOperadorUrl', e);
            }}
            hidden
          />
        </div>
        <button onClick={handleSaveOperadorSignature} disabled={operadorSignatureSaved}>
        Guardar Firma Operador
      </button>      </div>
      {firmaOperadorUrl && (
        <img src={firmaOperadorUrl} alt="Firma del operador" className="img-fluid" />
      )}
      <div>
        <h2>Intercambista</h2>
        <p className="text-muted">
          Por este medio certifico que con esta esta fecha inspeccione cuidadosamente el equipo y que este es un reporte
          fiel y correcto de dicha inspeccion
        </p>
        <div className="contenedor-firma">
          <SignatureCanvas
            ref={firmaIntercambistaCanvasRef}
            canvasProps={{
              width: 400,
              height: 200,
              id: "firmaIntercambistaCanvas", 
            }}
          />

        </div>
        <button onClick={handleSaveIntercambistaSignature} disabled={intercambistaSignatureSaved}>
        Guardar Firma Intercambista
      </button>      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <div className="progress">
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                  Inicio
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Tractor
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Izquierda
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Trasera
                </div>
                <div className="progress-bar bg-success " role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Derecha
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.9%" }} aria-valuenow={0} aria-valuemin={0}>
                  Firma
                </div>
              </div>
            </div>
            <div className="col-6 text-end text-right">
              <button id="siguienteEtapa" className="btn btn-success" onClick={handleSiguienteClick} disabled={!imagesSelected} >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>

  );
};


export default Etapas6;