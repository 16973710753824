import React, { useState, useEffect, ChangeEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown, Collapse } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa3, Etapa31 } from '../services/etapa1';

//Etapa funcionando

type NewPageProps = {
  WrappedComponent: React.ComponentType<any>;
};

const Etapas3: React.FC<NewPageProps> = ({ WrappedComponent }) => {

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

  const handleToggleMenu = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    window.location.href = '/';
  };

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" className="fixed-top">
        <Navbar.Brand>
          <img src={usuario} alt="User Logo" height="50" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggleMenu}>
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse in={!menuCollapsed}>
          <Nav>
            <NavDropdown title="Usuario" id="user-dropdown">
              <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div style={{ paddingTop: '80px' }}>
        <div className="container-fluid" style={{ height: '100vh' }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="content" style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                <Remolque />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Remolque: React.FC = () => {
  const [selectedCard, setSelectedCard] = useState<string>('tab-1');
  const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
      remolqueVin: null,
      numEconomico: null,
  });

  const [imageSrcRemolqueVin, setImageSrcRemolqueVin] = useState('');
  const [imageSrcNumEconomico, setImageSrcNumEconomico] = useState('');

  const [imagesSelected, setImagesSelected] = useState(false);
  useEffect (() => {
    const allImagesSelected = Object.values(imagesData).every(image => image !== null);
    setImagesSelected(allImagesSelected);
  }, [imagesData]);

  

  useEffect(() => {
      showContent(selectedCard);
  }, [selectedCard]);

  const handleCardSelect = (cardId: string) => {
      setSelectedCard(cardId);
  };

  const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (files && files[0]) {
          setImagesData((prevImagesData) => ({
              ...prevImagesData,
              [id]: files[0],
          }));
      }
  };

  const handleImageUploadRemolqueVin = async (event:any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageUrl = URL.createObjectURL(file);
        setImageSrcRemolqueVin(imageUrl);
      } catch (error){
        console.error('Error al cargar la imagen');

      }
    }
  };

  const handleImageUploadNumEconomico = async (event:any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageUrl = URL.createObjectURL(file);
        setImageSrcNumEconomico(imageUrl);
      } catch (error) {
        console.error('Error al cargar la imagen');
      }
    }
  };




  
  
  const handleSiguienteClick = async () => {
    const filenames: { [key: string]: string | null | number } = {};
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? '';
  
    for (const key in imagesData) {
      if (imagesData.hasOwnProperty(key)) {
        const file = imagesData[key];
        if (file) {
          const filename = await uploadImageToFirebaseStorage(file);
          filenames[key] = filename;
        }
      }
    }
  
    filenames['estatus'] = 4;
  
    try {
      console.log(filenames);
      const response = await Etapa3(id, filenames);     
      setImageSrcRemolqueVin('');
      setImageSrcNumEconomico(''); 
      window.location.href = `/Etapa3_1?id=${id}`;


    } catch (error: any) {
      console.error('Error en la API:', error.message);
    }
  };
  
  
  
  const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
      return new Promise((resolve, reject) => {
          const storageRef = firebase.storage().ref();
  
          const timestamp = Date.now();
          const randomNumber = Math.floor(Math.random() * 1000); 
          const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;
  
          const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);
  
          imageRef
              .put(file)
              .then(() => {
                  resolve(uniqueFilename);
              })
              .catch((error) => {
                  console.error('Error al subir la imagen:', error);
                  reject(error);
              });
      });
  };
  

  const showContent = (cardId: string) => {

  };
  

  return (
    <div className="container-fluid p-0">
    <h1 className="h3 mb-3">Remolque: Parte izquierda</h1>
    <div className="row">
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Numero Economico</h5>
          </div>
          <div className="card-body">
            <img
              id="Intercambio_remolqueNoEcoImg"
              className="img-thumbnail rounded mr-2 mb-2"
              src={ imageSrcNumEconomico ||camara}
              alt="Placeholder"
              width="140"
              height="140"
            />
            <div className="col-sm-10">
              <input
                  onChange={(e) =>{
                    handleImageUploadNumEconomico(e),
                    handleImageChange('numEconomico', e)
                  }}
                
                id="Intercambio_remolqueNoEcoFoto"
                name="Intercambio_remolqueNoEcoFoto"
                type="file"
                accept="image/*"
                capture
                className="form-control"
              />
              <input
                id="Intercambio_remolqueNoEcoFotoUrl"
                name="Intercambio_remolqueNoEcoFotoUrl"
                type="text"
                className="form-control"
                hidden
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-header">
            <h5 className="card-title mb-0">Manitas</h5>
          </div>
          <div className="card-body">
            <img
              id="Intercambio_remolqueVinImg"
              className="img-thumbnail rounded mr-2 mb-2"
              src={ imageSrcRemolqueVin||camara }
              alt="Placeholder"
              width="140"
              height="140"
            />
            <div className="col-sm-10">
              <input
                   onChange={(e) =>{
                  handleImageUploadRemolqueVin(e),
                  handleImageChange('remolqueVin', e)
                }}
                id="Intercambio_remolqueVinFoto"
                name="Intercambio_remolqueVinFoto"
                type="file"
                accept="image/*"
                capture
                className="form-control"
              />
              <input
                id="Intercambio_remolqueVinFotoUrl"
                name="Intercambio_remolqueVinFotoUrl"
                type="text"
                className="form-control"
                hidden
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div><br></br></div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <div className="progress">
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                  Inicio
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Tractor
                </div>
                <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Izquierda
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Trasera
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  20%
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0}>
                </div>
              </div>
            </div>
            <div className="col-6 text-end text-right">
              <button id="siguienteEtapa" disabled={!imagesSelected} className="btn btn-success" onClick={handleSiguienteClick}>
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );



};

export default Etapas3;
