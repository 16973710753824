import camara from '../images/photos/photoCamera.png';
import { Nav, Navbar, NavDropdown, Collapse } from 'react-bootstrap';
import { FaPlus, FaBars } from 'react-icons/fa';
import React, { useState, useEffect, ChangeEvent } from 'react';
import usuario from '../images/usuario.jpg';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa21 } from '../services/etapa1';
import { assert } from 'console';

//Etapa funcionando y probada , incluyendo las funciones que ayudan a validar que las imagenes siempre sean tomadas y subidas a firebase.



type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
};


const Etapas2_1: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);
 
    const handleToggleMenu = () => {
        ;
        setMenuCollapsed(!menuCollapsed)
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };

    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                < Etapa2_1 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );


};



const Etapa2_1: React.FC = () => {
    
    const [imageSrcTractoIzqUrl, setImageSrcTractoIzqUrl] = useState('');
    const [imageSrcTractoFrenteUrl, setImageSrcTractoFrenteUrl] = useState('');
    const [imageSrcTractoDerUrl, setImageSrcTractoDerUrl] = useState('');
    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        tractoIzqUrl : null,
        tractoFrenteUrl : null,
        tractoDerUrl : null,
        
    });



    const [imagesSelected, setImagesSelected]  = useState(false);

    useEffect (() => {
        const  allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);

    },  [imagesData]);
  


    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }));
        }
    };


    const handleImageUploadTractoIzqUrl = async (event:any) => {
        const file = event.target.files[0];
        if (file)  {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcTractoIzqUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        };
    };

    const handleImageUploadTractoFrenteUrl = async (event:any) => {
        const file = event.target.files[0];
        if (file) {
            try {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcTractoFrenteUrl(imageUrl)
          } catch (error) {
            console.error('Error al cargar la imagen');
          }
        };
    };

    const handleImageUploadTractoDerUrl = async (event:any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcTractoDerUrl(imageUrl);

            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        }
    }


    const handleSiguienteClick = async () => {
        const filenames: { [key: string]: string | null } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 3;
    
        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)) {
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }
        }
    
        try {
            const payload = {
                tractoIzqUrl: filenames['tractoIzqUrl'] || null,
                tractoFrenteUrl: filenames['tractoFrenteUrl'] || null,
                tractoDerUrl: filenames['tractoDerUrl'] || null,
                estatus,
            };
    
            console.log('Payload a enviar:', payload);
            const response = await Etapa21(id, payload); 
            setImageSrcTractoIzqUrl('');
            setImageSrcTractoDerUrl('');
            setImageSrcTractoFrenteUrl('');
            window.location.href = `/Etapa3?id=${id}`;
        } catch (error: any) {
            console.error('Error en la API:', error.message);
        }
    };

    
    
    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();
    
            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000); 
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;
    
            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);
    
            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };
    


    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Tractor</h1>

            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Izquierda del tractor</h5>
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_tractorIzqImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={ imageSrcTractoIzqUrl||camara}
                                alt="Izquierda del tractor"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_tractorIzqFotoCanvas" hidden></canvas>
                            <div className="col-sm-10">
                                <input
                                       onChange={(e) =>{
                                        handleImageUploadTractoIzqUrl(e),
                                        handleImageChange('tractoIzqUrl', e)
                                    }}
                                    id="Intercambio_tractorIzqFoto"
                                    name="Intercambio_tractorIzqFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_tractorIzqFotoUrl"
                                    name="Intercambio_tractorIzqFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Frente del tractor</h5>
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_tractorFrenteImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={  imageSrcTractoFrenteUrl||camara}
                                alt="Frente del tractor"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_tractorFrenteFotoCanvas" hidden></canvas>
                            <div className="col-sm-10">
                                <input
                                     onChange={(e) =>{
                                        handleImageUploadTractoFrenteUrl(e),
                                        handleImageChange('tractoFrenteUrl', e)
                                    }}
                                    id="Intercambio_tractorFrenteFoto"
                                    name="Intercambio_tractorFrenteFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_tractorFrenteFotoUrl"
                                    name="Intercambio_tractorFrenteFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Derecha del tractor</h5>
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_tractorDerImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={ imageSrcTractoDerUrl||camara}
                                alt="Derecha del tractor"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_tractorDerFotoCanvas" hidden></canvas>
                            <div className="col-sm-10">
                                <input
                                    onChange={(e) =>{
                                        handleImageUploadTractoDerUrl(e),
                                        handleImageChange('tractoDerUrl', e)
                                    }}
                                    id="Intercambio_tractorDerFoto"
                                    name="Intercambio_tractorDerFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_tractorDerFotoUrl"
                                    name="Intercambio_tractorDerFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                    Inicio
                                </div>
                                <div className="progress-bar" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Tractor
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Izquierda
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-end text-right">
                            <button onClick={handleSiguienteClick} disabled={!imagesSelected} id="siguienteEtapa" className="btn btn-success">
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Etapas2_1;
