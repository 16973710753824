import React, { useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Navbar, Nav, NavDropdown, Collapse } from "react-bootstrap";
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from "react-icons/fa";
import firebase from "firebase/compat/app";
import 'firebase/compat/storage';
import camara from '../images/photos/photoCamera.png'
import { Etapa34 } from "../services/etapa1";


//Etapa funcionando al 100 %


type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
};

const Etapas3_4: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };


    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa3_4 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};


const Etapa3_4 = () => {

    const [imageSrcIntercambio_remolqueIzqDano1FotoUrl, setImageSrcIntercambio_remolqueIzqDano1FotoUrl] = useState('');
    const [imageSrcIntercambio_remolqueIzqDano2FotoUrl, setImageSrcIntercambio_remolqueIzqDano2FotoUrl] = useState('');
    const [imageSrcIntercambio_remolqueIzqDano3FotoUrl, setImageSrcIntercambio_remolqueIzqDano3FotoUrl] = useState('');
    const [imageSrcIntercambio_remolqueIzqDano4FotoUrl, setImageSrcIntercambio_remolqueIzqDano4FotoUrl] = useState('');


    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        Intercambio_remolqueIzqDano1FotoUrl: null,
        Intercambio_remolqueIzqDano2FotoUrl: null,
        Intercambio_remolqueIzqDano3FotoUrl: null,
        Intercambio_remolqueIzqDano4FotoUrl: null,
    });

    const [imagesSelected , setImagesSelected] = useState(false);
    useEffect (() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);
    }, [imagesData]);
 

    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }));
        }
    };

    const handleImageUploadIntercambio_remolqueIzqDano1FotoUrl = async (event : any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcIntercambio_remolqueIzqDano1FotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        };
    };

    const  handleImageUploadIntercambio_remolqueIzqDano2FotoUrl = async (event : any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcIntercambio_remolqueIzqDano2FotoUrl(imageUrl);

            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        };
    };

    const handleImageUploadIntercambio_remolqueIzqDano3FotoUrl = async (event : any)  => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcIntercambio_remolqueIzqDano3FotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        };
    };

    const handleImageUploadIntercambio_remolqueIzqDano4FotoUrl = async (event : any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcIntercambio_remolqueIzqDano4FotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        };
    };

    const textareaElement: HTMLTextAreaElement | null = document.getElementById("Intercambio_remolqueIzqObservaciones") as HTMLTextAreaElement | null;

    let remolqueIzqObservaciones: string | null = null;
    
    if (textareaElement !== null) {
      remolqueIzqObservaciones = textareaElement.value;
    }
    
    





    const handleSiguienteClick = async () => {
        const filenames: { [key: string]: string | null } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 7;

        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)) {
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }
        }

        try {
            const payload = {
                Intercambio_remolqueIzqDano1FotoUrl: filenames['Intercambio_remolqueIzqDano1FotoUrl'] || null,
                Intercambio_remolqueIzqDano2FotoUrl: filenames['Intercambio_remolqueIzqDano2FotoUrl'] || null,
                Intercambio_remolqueIzqDano3FotoUrl: filenames['Intercambio_remolqueIzqDano3FotoUrl'] || null,
                Intercambio_remolqueIzqDano4FotoUrl: filenames['Intercambio_remolqueIzqDano4FotoUrl'] || null,
                estatus,
                Intercambio_remolqueIzqObservaciones: remolqueIzqObservaciones

            };

            console.log('Payload a enviar:', payload);
            const response = await Etapa34(id, payload);
            setImageSrcIntercambio_remolqueIzqDano1FotoUrl('');
            setImageSrcIntercambio_remolqueIzqDano2FotoUrl('');
            setImageSrcIntercambio_remolqueIzqDano3FotoUrl('');
            setImageSrcIntercambio_remolqueIzqDano4FotoUrl('');
            window.location.href = `/Etapa4?id=${id}`;
        } catch (error: any) {
            console.error('Error en la API:', error.message);
        }
    };

    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };

    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte izquierda</h1>

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Comentario</h5>
                        </div>
                        <textarea
                            id="Intercambio_remolqueIzqObservaciones"
                            name="Intercambio_remolqueIzqObservaciones"
                            className="form-control"
                            placeholder="observaciones"
                            rows={3}
                        />
                    </div>
                </div>
            </div>
            <br />



            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <img
                            className="img-thumbnail rounded mr-2 mb-2"
                            src={ imageSrcIntercambio_remolqueIzqDano1FotoUrl || camara}
                            alt="Placeholder"
                            width={140}
                            height={140}
                        />
                        <canvas id={`Intercambio_remolqueIzqDano1FotoCanvas`} hidden></canvas>
                        <div className="card-header">
                            <h5 className="card-title mb-0">Daño 1 </h5>
                        </div>
                        <div className="col-sm-10">
                            <input
                                onChange={(e) => {
                                    handleImageUploadIntercambio_remolqueIzqDano1FotoUrl(e),
                                    handleImageChange('Intercambio_remolqueIzqDano1FotoUrl', e)
                                }}
                                id={`Intercambio_remolqueIzqDano1Foto`}
                                name={`Intercambio_remolqueIzqDano1Foto`}
                                type="file"
                                accept="image/*"
                                capture
                                className="form-control"
                            />
                            <input
                                id={`Intercambio_remolqueIzqDano1FotoUrl`}
                                name={`Intercambio_remolqueIzqDano1FotoUrl`}
                                type="text"
                                className="form-control"
                                hidden
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <img
                            id={`Intercambio_remolqueIzqDano2Img`}
                            className="img-thumbnail rounded mr-2 mb-2"
                            src={ imageSrcIntercambio_remolqueIzqDano2FotoUrl || camara}
                            alt="Placeholder"
                            width={140}
                            height={140}
                        />
                        <canvas id={`Intercambio_remolqueIzqDano2FotoCanvas`} hidden></canvas>
                        <div className="card-header">
                            <h5 className="card-title mb-0">Daño 2</h5>
                        </div>
                        <div className="col-sm-10">
                            <input
                                onChange={(e) => {
                                    handleImageUploadIntercambio_remolqueIzqDano2FotoUrl(e) ,
                                    handleImageChange('Intercambio_remolqueIzqDano2FotoUrl', e)
                                }}
                                id={`Intercambio_remolqueIzqDano2Foto`}
                                name={`Intercambio_remolqueIzqDano2Foto`}
                                type="file"
                                accept="image/*"
                                capture
                                className="form-control"
                            />
                            <input
                                id={`Intercambio_remolqueIzqDano2FotoUrl`}
                                name={`Intercambio_remolqueIzqDano2FotoUrl`}
                                type="text"
                                className="form-control"
                                hidden
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <img
                            id={`Intercambio_remolqueIzqDano3Img`}
                            className="img-thumbnail rounded mr-2 mb-2"
                            src={imageSrcIntercambio_remolqueIzqDano3FotoUrl || camara}
                            alt="Placeholder"
                            width={140}
                            height={140}
                        />
                        <canvas id={`Intercambio_remolqueIzqDano3FotoCanvas`} hidden></canvas>
                        <div className="card-header">
                            <h5 className="card-title mb-0">Daño 3</h5>
                        </div>
                        <div className="col-sm-10">
                            <input
                                onChange={(e) => {
                                    handleImageUploadIntercambio_remolqueIzqDano3FotoUrl(e),
                                    handleImageChange('Intercambio_remolqueIzqDano3FotoUrl', e)
                                }}
                                id={`Intercambio_remolqueIzqDano3Foto`}
                                name={`Intercambio_remolqueIzqDano3Foto`}
                                type="file"
                                accept="image/*"
                                capture
                                className="form-control"
                            />
                            <input
                                id={`Intercambio_remolqueIzqDano3FotoUrl`}
                                name={`Intercambio_remolqueIzqDano3FotoUrl`}
                                type="text"
                                className="form-control"
                                hidden
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <div className="row">
                <div className="col-12 col-md-6">
                    <div className="card">
                        <img
                            id={`Intercambio_remolqueIzqDano4Img`}
                            className="img-thumbnail rounded mr-2 mb-2"
                            src={ imageSrcIntercambio_remolqueIzqDano4FotoUrl || camara}
                            alt="Placeholder"
                            width={140}
                            height={140}
                        />
                        <canvas id={`Intercambio_remolqueIzqDano4FotoCanvas`} hidden></canvas>
                        <div className="card-header">
                            <h5 className="card-title mb-0">Daño 4</h5>
                        </div>
                        <div className="col-sm-10">
                            <input
                                onChange={(e) => {
                                    handleImageUploadIntercambio_remolqueIzqDano4FotoUrl(e);
                                    handleImageChange('Intercambio_remolqueIzqDano4FotoUrl', e)
                                }}
                                id={`Intercambio_remolqueIzqDano4Foto`}
                                name={`Intercambio_remolqueIzqDano4Foto`}
                                type="file"
                                accept="image/*"
                                capture
                                className="form-control"
                            />
                            <input
                                id={`Intercambio_remolqueIzqDano4FotoUrl`}
                                name={`Intercambio_remolqueIzqDano4FotoUrl`}
                                type="text"
                                className="form-control"
                                hidden
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                    Inicio
                                </div>
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Tractor
                                </div>
                                <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Izquierda
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Trasera
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-end text-right">
                            <button id="siguienteEtapa" onClick={handleSiguienteClick} className="btn btn-success" disabled={!imagesSelected}>
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};








export default Etapas3_4;