import React, { useEffect, useState } from 'react';
import { tablaIntercambios } from '../services/Intercambios';
import palomita from '../images/photos/palomita.png';

interface Intercambio {
  id: number;
  folio: string;
  remolque: string;
  estatus: number;
  estatusDescripcion: string;
  estatusDescripcion2: string;
  intercambista: string;
}

const MyComponent: React.FC = () => {
  const [data, setData] = useState<Intercambio[]>([]);


  const loadFormOpen = (id: number, estatus: number) => {
    if (estatus === 1) {
      window.location.href = `/Etapa2?id=${id}`;
    } else if (estatus === 2) {
      window.location.href = `/etapa2_1?id=${id}`;
    } else if (estatus === 3) {
      window.location.href = `/etapa3?id=${id}`;
    } else if (estatus === 4) {
      window.location.href = `/etapa3_1?id=${id}`;
    } else if (estatus === 5) {
      window.location.href = `/etapa3_2?id=${id}`;
    } else if (estatus === 6) {
      window.location.href = `/etapa3_4?id=${id}`;
    } else if (estatus === 7) {
      window.location.href = `/etapa4?id=${id}`;
    } else if (estatus === 8) {
      window.location.href = `/etapa4_1?id=${id}`;
    } else if (estatus === 9) {
      window.location.href = `/etapa5_1?id=${id}`;
    } else if (estatus === 10) {
      window.location.href = `/etapa5?id=${id}`;
    } else if (estatus === 11 || estatus === 12) {
      window.location.href = `/etapa5_3?id=${id}`;
    } else if (estatus === 13) {
      window.location.href = `/etapa6?id=${id}`;
    } else if (estatus === 14) {
      window.location.href = '/Home';
    } else {
      console.log(`Estado ${estatus} no tiene acción definida.`);
    }
  };

  useEffect(() => {
    const obtenerDatos = async () => {
      try {
        const newData = await tablaIntercambios();
        setData(newData.Result);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    obtenerDatos();
  }, []);

  return (
    <div className="card flex-fill">
      <div className="card-header">
        <h5 className="card-title mb-0">Ultimos Intercambios</h5>
      </div>
      <div className="table-responsive">
        <table className="table table-striped table-sm w-100">
          <thead>
            <tr>
              <th>Folio</th>
              <th>Remolque</th>
              <th>Status</th>
              <th>Intercambista</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {data.map((intercambio) => (
              <tr key={intercambio.id} data-rowid={intercambio.id.toString()} className="dataRow">
                <td>{intercambio.folio}</td>
                <td>{intercambio.remolque}</td>
                <td onClick={() => loadFormOpen(intercambio.id, intercambio.estatus)}>
                  <span className={intercambio.estatusDescripcion}>{intercambio.estatusDescripcion2}</span>
                </td>
                <td className="text-nowrap">{intercambio.intercambista}</td>
                <td className="table-action">
                  <img
                    onClick={() => loadFormOpen(intercambio.id, intercambio.estatus)}
                    src={palomita}
                    width="16"
                    height="16"
                    alt="Acciones"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyComponent;
