import React, { useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa41 } from "../services/etapa1";

//Etapa funcionando al 100% , sanitizando la salida y el cambio de etapa para mejorar la optimizacion del cache

type NewPageProps = {
  WrappedComponent: React.ComponentType<any>;
}

const Etapas4_1: React.FC<NewPageProps> = ({ WrappedComponent }) => {
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

  const handleToggleMenu = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    window.location.href = '/';
  };


  return (
    <div>
      <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
        <Navbar.Brand>
          <img src={usuario} alt='User Logo' height='50' />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse in={!menuCollapsed}>
          <Nav>
            <NavDropdown title="Usuario" id='user-dropdown'>
              <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div style={{ paddingTop: '80px' }}>
        <div className='container-fluid' style={{ height: '100vh' }}>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                <Etapa4_1 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );

};


const Etapa4_1 = () => {
  type CheckedValues = {
    [key: string]: boolean;
  };

  const [imageSrcCajaInterior, setImageSrcCajaInterior] = useState('');
  const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
    Intercambio_interiorUrl: null
  });
  const [imagesSelected, setImagesSelected] = useState(false);
  useEffect(() => {
    const allImagesSelected = Object.values(imagesData).every(image => image !== null);
    setImagesSelected(allImagesSelected);
  },[imagesData]);



  const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      setImagesData((prevImagesData) => ({
        ...prevImagesData,
        [id]: files[0],
      }))
    }
  };



  const [checkedValues, setCheckedValues] = useState<CheckedValues>({
    herrajes: true,
    cintaReflejante: true,
    empaquePuertas: true,
    lucesFrenado: true,
    lucesIntermitentes: true

  });
  const handleCheckboxChange = (checkboxName: string) => {
    setCheckedValues((prevValues) => ({
      ...prevValues,
      [checkboxName]: !prevValues[checkboxName],
    }));
  };

  const handleImageUploadCajaInterior = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageSrcCajaInterior(imageUrl);
    }
  };

  const handleSiguienteClick = async () => {
    const filenames: { [key: string]: string | null } = {};
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? '';
    const estatus = 9;

    for (const key in imagesData) {
      if (imagesData.hasOwnProperty(key)) {
        const file = imagesData[key];
        if (file) {
          const filename = await uploadImageToFirebaseStorage(file);
          filenames[key] = filename;
        }
      }
    }

    try {
      const data = {
        estatus,
        interiorUrl : filenames.Intercambio_interiorUrl,
        empaquePuertas : checkedValues.empaquePuertas,
        cintaReflejante : checkedValues.cintaReflejante,
        herrajes : checkedValues.herrajes,
        lucesFrenado : checkedValues.lucesFrenado,
        lucesIntermitentes : checkedValues.lucesIntermitentes
      };

      console.log(data)
      const response = await Etapa41(id, data);
      setImageSrcCajaInterior('');
      window.location.href = `/Etapa5?id=${id}`;

    } catch (error: any) {
      console.error('Error en la api :', error.message);
    }
  };


  const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();

      const timestamp = Date.now();
      const randomNumber = Math.floor(Math.random() * 1000);
      const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

      const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

      imageRef
        .put(file)
        .then(() => {
          resolve(uniqueFilename);
        })
        .catch((error) => {
          console.error('Error al subir la imagen:', error);
          reject(error);
        });
    });
  };


  return (
    <div className="container-fluid p-0">
      <h1 className="h3 mb-3">Remolque: Parte trasera</h1>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Interior</h5>
            </div>
            <div className="card-body">
              <form>
                <div className="card-body">
                  <img
                    className="img-thumbnail rounded mr-2 mb-2"
                    src={  imageSrcCajaInterior ||camara}
                    alt="Placeholder"
                    width="140"
                    height="140"
                    id="Intercambio_interiorImg"
                  />
                  <canvas id="Intercambio_interiorCanvas" hidden></canvas>
                  <div className="col-sm-10">
                    <h6 className="card-subtitle text-muted">Interior de la caja</h6>
                    <input
                      id="Intercambio_interior"
                      name="Intercambio_interior"
                      type="file"
                      accept="image/*"
                      capture
                      onChange={(e) => {
                        handleImageUploadCajaInterior(e);
                        handleImageChange('Intercambio_interiorUrl', e);
                    }}
                      className="form-control"
                    />
                    <input
                      id="Intercambio_interiorUrl"
                      name="Intercambio_interiorUrl"
                      type="text"
                      className="form-control"
                      hidden
                    />
                  </div>
                </div>
              </form>
              <div className="card-body">
                <div className="form-check form-switch">
                  <input
                    onChange={() => handleCheckboxChange('empaquePuertas')}
                    className="form-check-input"
                    type="checkbox"
                    id="Intercambio_empaquePuertas"
                    name="Intercambio_empaquePuertas"
                    value="1"
                    checked={checkedValues.empaquePuertas}
                  />
                  <label className="form-check-label" htmlFor="Intercambio_empaquePuertas">
                    Empaque de puertas
                  </label>
                  <input id="Intercambio_id" type="text" className="form-control" hidden />
                </div>
                <div className="form-check form-switch">
                  <input
                    onChange={() => handleCheckboxChange('cintaReflejante')}
                    className="form-check-input"
                    type="checkbox"
                    id="Intercambio_cintaReflejante"
                    name="Intercambio_cintaReflejante"
                    value="1"
                    checked={checkedValues.cintaReflejante}
                  />
                  <label className="form-check-label" htmlFor="Intercambio_cintaReflejante">
                    Cinta reflejante
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    onChange={() => handleCheckboxChange('herrajes')}
                    className="form-check-input"
                    type="checkbox"
                    id="Intercambio_herrajes"
                    name="Intercambio_herrajes"
                    value="1"
                    checked={checkedValues.herrajes}
                  />
                  <label className="form-check-label" htmlFor="Intercambio_herrajes">
                    Herrajes
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    onChange={() => handleCheckboxChange('lucesFrenado')}
                    className="form-check-input"
                    type="checkbox"
                    id="Intercambio_lucesFrenado"
                    name="Intercambio_lucesFrenado"
                    value="1"
                    checked={checkedValues.lucesFrenado}
                  />
                  <label className="form-check-label" htmlFor="Intercambio_lucesFrenado">
                    Luces de frenado
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    onChange={() => handleCheckboxChange('lucesIntermitentes')}
                    className="form-check-input"
                    type="checkbox"
                    id="Intercambio_lucesIntermitentes"
                    name="Intercambio_lucesIntermitentes"
                    value="1"
                    checked={checkedValues.lucesIntermitentes}
                  />
                  <label className="form-check-label" htmlFor="Intercambio_lucesIntermitentes">
                    Luces Intermitentes /Direccionales
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <div className="progress">
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                  Inicio
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Tractor
                </div>
                <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Izquierda
                </div>
                <div className="progress-bar " role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Trasera
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Derecha
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0}>
                </div>
              </div>
            </div>
            <div className="col-6 text-end text-right">
              <button id="siguienteEtapa" className="btn btn-success" onClick={handleSiguienteClick} disabled={!imagesSelected} >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};



export default Etapas4_1;