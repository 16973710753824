import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import HomePage from './pages/Home';
import RequireAuth from './pages/RequireAuth';
import NewPage from './pages/etapa2_Home';
import Etapas3 from './pages/etapa3';
import Etapas3_1 from './pages/etapa3.1';
import Etapas3_2 from './pages/etapa3.2';
import Etapas2_1 from './pages/etapa2.1';
import pajaro from './images/pajaro.gif';
import Etapas3_3 from './pages/etapa3.3';
import Etapas3_4 from './pages/etapa3.4';
import Etapas4 from './pages/etapa4';
import Etapas4_1 from './pages/etapa4.1';
import Etapas5 from './pages/etapa5';
import Etapas5_1 from './pages/etapa5.1';
import Etapas5_3 from './pages/etapa5.3';
import Etapas5_2 from './pages/etapa5.2';
import Etapas6 from './pages/etapa6';


const NotFound: React.FC = () => {
  return (
    <div>
      <h1>404 Not Found</h1>
      <p>La página que buscas no existe.</p>
      <img src={pajaro} alt="Pájaro" />
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Home" element={<RequireAuth WrappedComponent={HomePage} />} />
        <Route path="/Etapa2" element={<RequireAuth WrappedComponent={NewPage} />} />
        <Route path="/Etapa2_1" element={<RequireAuth WrappedComponent={Etapas2_1} />} />
        <Route path="/Etapa3" element={<RequireAuth WrappedComponent={Etapas3} />} />
        <Route path="/Etapa3_1" element={<RequireAuth WrappedComponent={Etapas3_1} />} />
        <Route path="/Etapa3_2" element={<RequireAuth WrappedComponent={Etapas3_2} />} />
        <Route path="/Etapa3_3" element={<RequireAuth WrappedComponent={Etapas3_3} />} />
        <Route path="/Etapa3_4" element={<RequireAuth WrappedComponent={Etapas3_4} />} />
        <Route path="/Etapa4" element={<RequireAuth WrappedComponent={Etapas4} />} />
        <Route path="/Etapa4_1" element={<RequireAuth WrappedComponent={Etapas4_1} />} />
        <Route path="/Etapa5" element={<RequireAuth WrappedComponent={Etapas5} />} />
        <Route path="/Etapa5_1" element={<RequireAuth WrappedComponent={Etapas5_1} />} />
        <Route path="/Etapa5_2" element={<RequireAuth WrappedComponent={Etapas5_2} />} />
        <Route path="/Etapa5_3" element={<RequireAuth WrappedComponent={Etapas5_3} />} />
        <Route path="/Etapa6" element={<RequireAuth WrappedComponent={Etapas6} />} />





        <Route path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};


export default App;
