import React, { useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa53 } from "../services/etapa1";


//Etapa funcionando al 100% , sanitizacion de cache y limpia en la salida de datos al cambiar de etapa
type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
}

const Etapas5_3: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };


    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa5_3 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};

const Etapa5_3 = () => {

    const [imageSrcDano1, setImageSrcDano1] = useState('');
    const [imageSrcDano2, setImageSrcDano2] = useState('');
    const [imageSrcDano3, setImageSrcDano3] = useState('');
    const [imageSrcDano4, setImageSrcDano4] = useState('');
    const [imagesData, setImagesData] = useState <{[key:string]: File | null}>({
        Intercambio_remolqueDerDano1FotoUrl : null,
        Intercambio_remolqueDerDano2FotoUrl : null,
        Intercambio_remolqueDerDano3FotoUrl : null,
        Intercambio_remolqueDerDano4FotoUrl : null
    });
    const [imagesSelected, setImagesSelected] = useState(false);
    useEffect(() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);
    }, [imagesData]);

    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }))
        }
    };


    const handleImageUploadDano1 = (event: any) =>{
        const file = event.target.files[0];
        if (file){
            const imageUrl = URL.createObjectURL(file);
            setImageSrcDano1(imageUrl);
        }
    };
    const handleImageUploadDano2 = (event:any) => {
        const file = event.target.files[0];
        if (file){
            const imageUrl = URL.createObjectURL(file);
            setImageSrcDano2(imageUrl);
        }
    };
    const handleImageUploadDano3 = (event:any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcDano3(imageUrl);
        }
    };
    const handleImageUploadDano4 = (event:any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcDano4(imageUrl);
        }
    };
    const Intercambio_remolqueDerObservaciones: HTMLTextAreaElement | null = document.getElementById("Intercambio_remolqueDerObservaciones") as HTMLTextAreaElement | null;

    let remolqueDerObservaciones: string | null = null;
    
    if (Intercambio_remolqueDerObservaciones !== null) {
      remolqueDerObservaciones = Intercambio_remolqueDerObservaciones.value;
    }
    


    const handleSiguienteClick = async () => {
        const filenames: { [key: string]: string | null } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 13;


        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)){
                const file = imagesData[key];
                if (file) {
                    const filename = await  uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }
        }

        try {
            const data = {
                estatus, 
                Intercambio_remolqueDerDano1FotoUrl : filenames.Intercambio_remolqueDerDano1FotoUrl,
                Intercambio_remolqueDerDano2FotoUrl : filenames.Intercambio_remolqueDerDano2FotoUrl,
                Intercambio_remolqueDerDano3FotoUrl : filenames.Intercambio_remolqueDerDano3FotoUrl,
                Intercambio_remolqueDerDano4FotoUrl : filenames.Intercambio_remolqueDerDano4FotoUrl,
                Intercambio_remolqueDerObservaciones : remolqueDerObservaciones
                
            };
            console.log(data)
            const response = await Etapa53(id, data);
            setImageSrcDano1('');
            setImageSrcDano2('');
            setImageSrcDano3('');
            setImageSrcDano4('');
            window.location.href = `/Etapa6?id=${id}`;
        } catch (error : any) {
            console.error("Error en la respuesta de la api", error.message)
        }

    }



    
    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };



    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte derecha</h1>
            <div className="row">
            <div className="mb-3">
							<label htmlFor="Intercambio_remolqueDerObservaciones" className="form-label">Comentario</label>
							<textarea id="Intercambio_remolqueDerObservaciones" name="Intercambio_remolqueDerObservaciones" className="form-control" placeholder="observaciones" ></textarea>
						</div>
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueDerDano1Img"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcDano1 ||camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueDerDano1FotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Daño 1</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueDerDano1Foto"
                                    name="Intercambio_remolqueDerDano1Foto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadDano1(e);
                                        handleImageChange('Intercambio_remolqueDerDano1FotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueDerDano1FotoUrl"
                                    name="Intercambio_remolqueDerDano1FotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
                

                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueDerDano2Img"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={ imageSrcDano2||camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueDerDano2FotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Daño 2</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueDerDano2Foto"
                                    name="Intercambio_remolqueDerDano2Foto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadDano2(e);
                                        handleImageChange('Intercambio_remolqueDerDano2FotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueDerDano2FotoUrl"
                                    name="Intercambio_remolqueDerDano2FotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div><br /></div>
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueDerDano3Img"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcDano3||camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueDerDano3FotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Daño 3</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueDerDano3Foto"
                                    name="Intercambio_remolqueDerDano3Foto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadDano3(e);
                                        handleImageChange('Intercambio_remolqueDerDano3FotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueDerDano3FotoUrl"
                                    name="Intercambio_remolqueDerDano3FotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueDerDano4Img"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcDano4||camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueDerDano4FotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Daño 4</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueDerDano4Foto"
                                    name="Intercambio_remolqueDerDano4Foto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadDano4(e);
                                        handleImageChange('Intercambio_remolqueDerDano4FotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueDerDano4FotoUrl"
                                    name="Intercambio_remolqueDerDano4FotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div><br /></div>
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row text-muted">
                            <div className="col-6 text-start">
                                <div className="progress">
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                        Inicio
                                    </div>
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Tractor
                                    </div>
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Izquierda
                                    </div>
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Trasera
                                    </div>
                                    <div className="progress-bar " role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Derecha
                                    </div>
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.9%" }} aria-valuenow={0} aria-valuemin={0}>
                                        Firma
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 text-end text-right">
                                <button id="siguienteEtapa" className="btn btn-success" onClick={handleSiguienteClick} disabled={!imagesSelected} >
                                    Siguiente
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>

    );
};
export default Etapas5_3;