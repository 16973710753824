//METODO GET
export async function Operador(nombre: string) {

  const apiUrl = `https://huskyapp.dxxpress.net/api/fill/${nombre}`;
  const token = obtenerToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'Operador'
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: headers,
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Error en la respuesta de la API');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function Llanta(nombre : string) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/fill/${nombre}`;
  const token = obtenerToken();


  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'RemolqueLlantas'
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'Get',
      headers: headers,
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Error en la respuesta de la api');
    }

  } catch(error) {
    console.error('Error: ', Error)
    throw error;
  };
};






export async function Transportista(nombre: string) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/fill/${nombre}`;
  const token = obtenerToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'Unidad'
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: headers,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Error en la respuesta de la API');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function Linea(nombre: string) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/fill/${nombre}`;
  const token = obtenerToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'Linea'
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: headers,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Error en la respuesta de la API');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function Remolque(nombre: string) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/fill/${nombre}`;
  const token = obtenerToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'Remolque'
  };

  try {
    const response = await fetch(apiUrl, {
      method: 'GET',
      headers: headers,
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error('Error en la respuesta de la API');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}


// METODOS POST Y PUT
export async function llenado(data: Record<string, any>): Promise<any> {
  const apiUrl = 'https://huskyapp.dxxpress.net/api/main';
  const token = obtenerToken();

  const postHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'Intercambio',
  };

  const body = JSON.stringify(data);
  try {
    const postResponse = await fetch(apiUrl, {
      method: 'POST',
      headers: postHeaders,
      body: body,
    });

    if (postResponse.ok) {
      return postResponse.json();
    } else {

      const errorResponseText = await postResponse.text();
      console.error('Error en la respuesta del POST:', errorResponseText);
      return null;
    }
  } catch (error) {
    console.error('Error:', error);
    return null;
  }
}
export async function actualizarRecurso(id: string, data: Record<string, any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase2',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function Etapa21(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase21',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function Etapa3(id: string, data: Record<string, any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase3',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function Etapa31(id:string, data:Record<string,any>){
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase31',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}

export async function Etapa32(id: string,data: Record<string, any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase32',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }

}
export async function Etapa33(id:string,data:Record<string,any>){
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase33',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function Etapa34(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase34',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function Etapa4_0(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase4',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}
export async function Etapa41(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase41',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
  
}
export async function Etapa5_0(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase5',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
  
}
export async function Etapa51(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase51',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
  
}
export async function Etapa52(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase52',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
  
}
export async function Etapa53(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase53',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
  
}
export async function Etapa6_0(id:string,data:Record<string,any>) {
  const apiUrl = `https://huskyapp.dxxpress.net/api/main/${id}`;
  const token = obtenerToken();

  const putHeaders: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'IntercambioRemolqueFase6',
  };

  const body = JSON.stringify(data);

  try {
    const putResponse = await fetch(apiUrl, {
      method: 'PUT',
      headers: putHeaders,
      body: body,
    });

    if (putResponse.ok) {
      const putResponseData = await putResponse.json();
      console.log(putResponse.ok);
      console.log(putResponse.status);
      console.log(putResponseData);
    } else {
      const errorResponseText = await putResponse.text();
      console.error('Error en la respuesta del PUT:', errorResponseText);
      throw new Error('Error en la respuesta del PUT');
    }
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
}


function obtenerToken() {
  return localStorage.getItem('token');
}

