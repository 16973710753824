import React, { useState, useEffect, ChangeEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown, Collapse } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa31 } from '../services/etapa1';

// Etapa funcionando,probada y toDO



type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
};



const Etapas3_1: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    }

    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa3_1 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
const firebaseConfig = {
    apiKey: "AIzaSyBLMWEbt-0XyH9XA5tbcFmcCvAHhvfjQg8",
    authDomain: "dxxpress-450bc.firebaseapp.com",
    projectId: "dxxpress-450bc",
    storageBucket: "dxxpress-450bc.appspot.com",
    messagingSenderId: "339195910480",
    appId: "1:339195910480:web:56b5156cb8dc5e6c3f7455",
    measurementId: "G-1TM8ZLK5CK"
};
firebase.initializeApp(firebaseConfig);
const Etapa3_1: React.FC = () => {
    type CheckedValues = {
        [key: string]: boolean;
    };

    const [imageSrcRemolqueCostadoTraseroIzquierdoFotoUrl, setImageSrcRemolqueCostadoTraseroIzquierdoFotoUrl] = useState('');
    const [imageSrcRemolqueCostadoFrenteIzquierdoFotoUrl, setImageSrcRemolqueCostadoFrenteIzquierdoFotoUrl] = useState('');
    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        remolqueCostadoTraseroIzquierdoFotoUrl: null,
        remolqueCostadoFrenteIzquierdoFotoUrl: null
    });

    const [imagesSelected, setImagesSelected] = useState(false);
    useEffect(() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);
    }, [imagesData]);

    const [checkedValues, setCheckedValues] = useState<CheckedValues>({
        remolqueInspeccionMecanica: true,
        remolqueLucesIzquierda: true,
        remolqueLucesGaliboIzqFrontalSup: true,
        remolqueManitasIzq: true,
        remolqueManivelaIzq: true,
        remolquePatinIzquierdo: true,
        remolqueCuartoLadoIzq: true,
        LoderaIzq: true,
        remolqueLucesIzqP2: true,
        LuzAbsIzq: true,
        luzBarcoIzq: true,
        rompevientosIzq: true

    });

    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [checkboxName]: !prevValues[checkboxName],
        }));
    };

    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }));

        };

    };

    const handleImageUploadRemolqueCostadoTraseroIzquierdoFotoUrl = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcRemolqueCostadoTraseroIzquierdoFotoUrl(imageUrl);

            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        };

    };


    const handleImageUploadRemolqueCostadoFrenteIzquierdoFotoUrl = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcRemolqueCostadoFrenteIzquierdoFotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen')
            }
        }

    };

    const handleSiguienteClick = async () => {
        const filenames: { [key: string]: string | null } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 5;


        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)) {
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }
        }

        try {
            const payload = {
                estatus,
                Intercambio_remolqueCostadoTraseroIzquierdoFotoUrl: filenames.remolqueCostadoTraseroIzquierdoFotoUrl,
                Intercambio_remolqueCostadoFrenteIzquierdoFotoUrl: filenames.remolqueCostadoFrenteIzquierdoFotoUrl,
                Intercambio_remolqueInspeccionMecanica: checkedValues.remolqueInspeccionMecanica,
                Intercambio_remolqueLucesIzquierda: checkedValues.remolqueLucesIzquierda,
                Intercambio_remolqueLucesGaliboIzqFrontalSup: checkedValues.remolqueLucesGaliboIzqFrontalSup,
                Intercambio_remolqueManitasIzq: checkedValues.remolqueManitasIzq,
                Intercambio_remolqueManivelaIzq: checkedValues.remolqueManivelaIzq,
                Intercambio_remolquePatinIzquierdo: checkedValues.remolquePatinIzquierdo,
                Intercambio_remolqueCuartoLadoIzq: checkedValues.remolqueCuartoLadoIzq,
                Intercambio_LoderaIzq: checkedValues.LoderaIzq,
                Intercambio_remolqueLucesIzqP: checkedValues.remolqueLucesIzqP2,
                Intercambio_LuzAbsIzq: checkedValues.LuzAbsIzq,
                Intercambio_luzBarcoIzq: checkedValues.luzBarcoIzq,
                Intercambio_rompevientosIzq: checkedValues.rompevientosIzq
            }
            console.log(payload);
            const response = await Etapa31(id, payload);
            setImageSrcRemolqueCostadoFrenteIzquierdoFotoUrl('');
            setImageSrcRemolqueCostadoTraseroIzquierdoFotoUrl('');
            window.location.href = `/Etapa3_2?id=${id}`;
        } catch (error: any) {
            console.error('Error en la api', error.message);

        }
    };
    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };

    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte izquierda</h1>
            <div className="row">
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Trasero izquierdo</h5>
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueCostadoTraseroIzquierdoImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcRemolqueCostadoTraseroIzquierdoFotoUrl || camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueCostadoTraseroIzquierdoFotoCanvas" hidden></canvas>
                            <div className="col-sm-10">
                                <input

                                    onChange={(e) => {
                                        handleImageUploadRemolqueCostadoTraseroIzquierdoFotoUrl(e),
                                            handleImageChange('remolqueCostadoTraseroIzquierdoFotoUrl', e);
                                    }}
                                    id="Intercambio_remolqueCostadoTraseroIzquierdoFoto"
                                    name="Intercambio_remolqueCostadoTraseroIzquierdoFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueCostadoTraseroIzquierdoFotoUrl"
                                    name="Intercambio_remolqueCostadoTraseroIzquierdoFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Frente izquierdo</h5>
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueCostadoFrenteIzquierdoImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcRemolqueCostadoFrenteIzquierdoFotoUrl || camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueCostadoFrenteIzquierdoFotoCanvas" hidden></canvas>
                            <div className="col-sm-10">
                                <input

                                    onChange={(e) => {
                                        handleImageUploadRemolqueCostadoFrenteIzquierdoFotoUrl(e),
                                            handleImageChange('remolqueCostadoFrenteIzquierdoFotoUrl', e);
                                    }}
                                    id="Intercambio_remolqueCostadoFrenteIzquierdoFoto"
                                    name="Intercambio_remolqueCostadoFrenteIzquierdoFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueCostadoFrenteIzquierdoFotoUrl"
                                    name="Intercambio_remolqueCostadoFrenteIzquierdoFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6">
                    <div className="card">

                        <div className="card-header">
                            <h5 className="card-title">Costado Izquierdo del Remolque P1</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-check form-switch">
                                <input

                                    onChange={() => handleCheckboxChange('remolqueInspeccionMecanica')}
                                    checked={checkedValues.remolqueInspeccionMecanica}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueInspeccionMecanica"
                                    name="Intercambio_remolqueInspeccionMecanica"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueInspeccionMecanica">
                                    Inspeccion Mecanica
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueLucesIzquierda')}
                                    checked={checkedValues.remolqueLucesIzquierda}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueLucesIzquierda"
                                    name="Intercambio_remolqueLucesIzquierda"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueLucesIzquierda">
                                    Luces en buen estado?
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.remolqueLucesGaliboIzqFrontalSup}
                                    onChange={() => handleCheckboxChange('remolqueLucesGaliboIzqFrontalSup')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueLucesGaliboIzqFrontalSup"
                                    name="Intercambio_remolqueLucesGaliboIzqFrontalSup"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueLucesGaliboIzqFrontalSup">
                                    Luz Galibo izq. Frontal Sup.
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.remolqueManitasIzq}
                                    onChange={() => handleCheckboxChange('remolqueManitasIzq')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueManitasIzq"
                                    name="Intercambio_remolqueManitasIzq"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueManitasIzq">
                                    Manitas
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.remolqueManivelaIzq}
                                    onChange={() => handleCheckboxChange('remolqueManivelaIzq')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueManivelaIzq"
                                    name="Intercambio_remolqueManivelaIzq"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueManivelaIzq">
                                    Manivela
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.remolquePatinIzquierdo}
                                    onChange={() => handleCheckboxChange('remolquePatinIzquierdo')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolquePatinIzquierdo"
                                    name="Intercambio_remolquePatinIzquierdo"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolquePatinIzquierdo">
                                    Patin Izquierdo
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h5 className="card-title">Costado Izquierdo del Remolque P2</h5>
                        </div>
                        <div className="card-body">
                            <div className="form-check form-switch">
                                <input
                                    checked={checkedValues.remolqueCuartoLadoIzq}
                                    onChange={() => handleCheckboxChange('remolqueCuartoLadoIzq')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueCuartoLadoIzq"
                                    name="Intercambio_remolqueCuartoLadoIzq"

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueCuartoLadoIzq">
                                    Cuarto lado izquierdo
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.LoderaIzq}
                                    onChange={() => handleCheckboxChange('LoderaIzq')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_LoderaIzq"
                                    name="Intercambio_LoderaIzq"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_LoderaIzq">
                                    Lodera Izquierda
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.remolqueLucesIzqP2}
                                    onChange={() => handleCheckboxChange('remolqueLucesIzqP2')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueLucesIzqP2"
                                    name="Intercambio_remolqueLucesIzqP2"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueLucesIzqP2">
                                    Luces en buen estado?
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.LuzAbsIzq}
                                    onChange={() => handleCheckboxChange('LuzAbsIzq')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_LuzAbsIzq"
                                    name="Intercambio_LuzAbsIzq"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_LuzAbsIzq">
                                    Luz ABS
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('luzBarcoIzq')}
                                    checked={checkedValues.luzBarcoIzq}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_luzBarcoIzq"
                                    name="Intercambio_luzBarcoIzq"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_luzBarcoIzq">
                                    Luz de barco izquierda
                                </label>
                                <br />
                                <input
                                    checked={checkedValues.rompevientosIzq}
                                    onChange={() => handleCheckboxChange('rompevientosIzq')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_rompevientosIzq"
                                    name="Intercambio_rompevientosIzq"
                                    value="1"
                                />
                                <label className="form-check-label" htmlFor="Intercambio_rompevientosIzq">
                                    Rompevientos Izquierdo
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                    Inicio
                                </div>
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Tractor
                                </div>
                                <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Izquierda
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Trasera
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-end text-right">
                            {/* Enable the "Siguiente" button if all checkboxes are checked */}
                            <button id="siguienteEtapa" onClick={handleSiguienteClick} className="btn btn-success" disabled={!imagesSelected}>
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );

};




export default Etapas3_1;

