import React, { useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png'
import { Etapa51, Llanta } from "../services/etapa1";
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { event } from "jquery";


// Etapa funcionando al 100%



type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
}

const Etapas5_1: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };


    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa5_1 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};

const Etapa5_1 = () => {
    type CheckedValues = {
        [key: string]: boolean;
    };

    //Marca Posicion 8
    const [selectedValueMarca8, setSelectedValueMarca8] = useState<any>(null);
    const [selectedOption, setSelectedOption] = useState<any>(null);
    const [inputValue, setInputValue] = useState('');
    const [marcaOptions, setMarcaOptions] = useState<any[]>([]);

    //Marca Posicion 7
    const [selectedValueMarca7, setSelectedValueMarca7] = useState<any>(null);
    const [selectedOptionMarca7, setSelectedOptionMarca7] = useState<any>(null);
    const [inputValueMarca7, setInputValueMarca7] = useState('');
    const [marcaOptions7, setMarcaOptions7] = useState<any[]>([]);

    //Marca Posicion 3
    const [selectedValueMarca3, setSelectedValueMarca3] = useState<any>(null);
    const [selectedOptionMarca3, setSelectedOptionMarca3] = useState<any>(null);
    const [inputValueMarca3, setInputValueMarca3] = useState('');
    const [marcaOptions3, setMarcaOptions3] = useState<any[]>([]);

    // Marca Posicion 4

    const [selectedValueMarca4, setSelectedValueMarca4] = useState<any>(null);
    const [selectedOptionMarca4, setSelectedOptionMarca4] = useState<any>(null);
    const [inputValueMarca4, setInputValueMarca4] = useState('');
    const [marcaOptions4, setMarcaOptions4] = useState<any[]>([]);




    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        Intercambio_remolqueChasisTraseroDerFotoUrl: null,
        Intercambio_remolqueLlantaDerEje2FotoUrl: null,
        Intercambio_remolqueLlantaDerEje1FotoUrl: null,
        Intercambio_remolqueChasisFrontalDerFotoUrl: null
    });
    const [imageSrcRemolqueChasisTraseroDerFotoUrl, setImageSrcRemolqueChasisTraseroDerFotoUrl] = useState('')
    const [imageSrcRemolqueLlantaDerEje2FotoUrl, setImageSrcRemolqueLlantaDerEje2FotoUrl] = useState('');
    const [imageSrcRemolqueLlantaDerEje1FotoUrl, setImageSrcRemolqueLlantaDerEje1FotoUrl] = useState('');
    const [imageSrcRemolqueChasisFrontalDerFotoUrl, setImageSrcRemolqueChasisFrontalDerFotoUrl] = useState('');
    const [imagesSelected, setImagesSelected] = useState(false);
    useEffect(() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);
    }, [imagesData]);

    const [checkedValues, setCheckedValues] = useState<CheckedValues>({
        remolqueLlantaDerJumbo: false,
        remolqueChasisTraseroDerAmortiguador: true,
        remolqueChasisTraseroDerBolsaAire: true,
        remolqueChasisTraseroDerGavilan: true,
        remolqueChasisTraseroDerMuelle: true,
        remolqueChasisTraseroDerRotachamber: true,
        //
        remolqueLlantaDerEje2BrilosPivote: true,
        remolqueLlantaDerEje2Posicion7: true,
        remolqueLlantaDerEje2Posicion8: true,
        remolqueLlantaDerEje2MasaYoyo: true,
        remolqueLlantaDerEje2Rin: true,
        remolqueLlantaDerEje2Lodera: true,
        //
        remolqueLlantaDerEje1BrilosPivote: true,
        remolqueLlantaDerEje1Posicion3: true,
        remolqueLlantaDerEje1Posicion4: true,
        remolqueLlantaDerEje1MasaYoyo: true,
        remolqueLlantaDerEje1Rin: true,
        remolqueLlantaDerEje1Lodera: true,
        //
        remolqueChasisFrontalDerAmortiguador: true,
        remolqueChasisFrontalDerBolsaAire: true,
        remolqueChasisFrontalDerGavilan: true,
        remolqueChasisFrontalDerMuelle: true,
        remolqueChasisFrontalDerRotachamber: true
    });

    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [checkboxName]: !prevValues[checkboxName],
        }));
    };

    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }))
        }
    };

    const handleImageUploadChasisTraseroDerecho = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcRemolqueChasisTraseroDerFotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        }
    };

    const handleImageUploadLlantaDerEje2 = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcRemolqueLlantaDerEje2FotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        }
    };

    const handleImageUploadLlantaDerEje1 = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcRemolqueLlantaDerEje1FotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen');
            }
        }
    };

    const handleImageUploadChasisFrontalDerecho = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcRemolqueChasisFrontalDerFotoUrl(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen ');
            }
        }

    };


    const cargarOpcionesYEstablecerEstado = async (inputValue: string, setInputValue: (value: string) => void, setEstadoOpciones: (options: any[]) => void) => {
        try {
            const respuesta = await Llanta(inputValue);

            if (respuesta && respuesta.Result) {
                const opcionesMarca = respuesta.Result.map((marca: any) => ({
                    value: marca.id,
                    label: marca.nombre
                }));
                setEstadoOpciones(opcionesMarca);
            } else {
                setEstadoOpciones([]);
            }
        } catch (error) {
            console.error('Error al cargar opciones de marca', error);
            setEstadoOpciones([]);
        }
    };

    useEffect(() => {
        cargarOpcionesYEstablecerEstado(inputValue, setInputValue, setMarcaOptions);
    }, [inputValue]);

    const handleInputChange = (inputValue: string) => {
        setInputValue(inputValue);
    };

    const handleSelectChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
    };

 
    useEffect(() => {
        cargarOpcionesYEstablecerEstado(inputValueMarca7, setInputValueMarca7, setMarcaOptions7);
    }, [inputValueMarca7]);
    const handleInputChangeMarca7 = (inputValueMarca7: string) => {
        setInputValueMarca7(inputValueMarca7);
    };

    const handleSelectChangeMarca7 = (selectedOptionMarca7: any) => {
        setSelectedOptionMarca7(selectedOptionMarca7);
    };

    useEffect(() => {
        cargarOpcionesYEstablecerEstado(inputValueMarca3, setInputValueMarca3, setMarcaOptions3);
    }, [inputValueMarca3]);
    const handleInputChangeMarca3 = (inputValueMarca3: string) => {
        setInputValueMarca3(inputValueMarca3);
    };

    const handleSelectChangeMarca3 = (selectedOptionMarca3: any) => {
        setSelectedOptionMarca3(selectedOptionMarca3);
    };

    useEffect(() => {
        cargarOpcionesYEstablecerEstado(inputValueMarca4, setInputValueMarca4, setMarcaOptions4);
    }, [inputValueMarca4]);
    const handleInputChangeMarca4 = (inputValueMarca4: string) => {
        setInputValueMarca4(inputValueMarca4);
    };

    const handleSelectChangeMarca4 = (selectedOptionMarca4: any) => {
        setSelectedOptionMarca4(selectedOptionMarca4);
    };


    const handleSelectChangeestatus7 = (valueEstatus7: string) => {
        setSelectedValueMarca7(valueEstatus7);
      };
      
      const handleSelectChangeMarca8 = (value: string) => {
        setSelectedValueMarca8(value);
      };
      
    const handleSelectChangeestatus3 = (valueEstatus3: string) => {
        setSelectedValueMarca3(valueEstatus3);
      };
      const handleSelectChangeestatus4 = (valueEstatus4: string) => {
        setSelectedValueMarca4(valueEstatus4);
      };


      


    const handleSiguienteClick = async () => {
        const filenames: { [key: string]: string | null | number } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 10;
        const { value } = selectedOption || {};
        const { value: valueMarca7 } = selectedOptionMarca7 || {};
        const { value: valueMarca3} = selectedOptionMarca3 || {};
        const { value: valueMarca4} = selectedOptionMarca4 || {};

        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)) {
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;

                }
            }
        }


        try {
            const data = {
                remolqueChasisTraseroDerFotoUrl: filenames.Intercambio_remolqueChasisTraseroDerFotoUrl,
                remolqueLlantaDerEje2FotoUrl: filenames.Intercambio_remolqueLlantaDerEje2FotoUrl,
                remolqueLlantaDerEje1FotoUrl: filenames.Intercambio_remolqueLlantaDerEje1FotoUrl,
                remolqueChasisFrontalDerFotoUrl: filenames.Intercambio_remolqueChasisFrontalDerFotoUrl,
                remolqueLlantaDerJumbo: checkedValues.remolqueLlantaDerJumbo,

                remolqueChasisTraseroDerAmortiguador: checkedValues.remolqueChasisTraseroDerAmortiguador,
                remolqueChasisTraseroDerBolsaAire: checkedValues.remolqueChasisTraseroDerBolsaAire,
                remolqueChasisTraseroDerGavilan: checkedValues.remolqueChasisTraseroDerGavilan,
                remolqueChasisTraseroDerMuelle: checkedValues.remolqueChasisTraseroDerMuelle,
                remolqueChasisTraseroDerRotachamber: checkedValues.remolqueChasisTraseroDerRotachamber,

                remolqueLlantaDerEje2BrilosPivote: checkedValues.remolqueLlantaDerEje2BrilosPivote,
                remolqueLlantaDerEje2Posicion7: checkedValues.remolqueLlantaDerEje2Posicion7,
                remolqueLlantaDerEje2Posicion8: checkedValues.remolqueLlantaDerEje2Posicion8,
                remolqueLlantaDerEje2MasaYoyo: checkedValues.remolqueLlantaDerEje2MasaYoyo,
                remolqueLlantaDerEje2Rin: checkedValues.remolqueLlantaDerEje2Rin,
                remolqueLlantaDerEje2Lodera: checkedValues.remolqueLlantaDerEje2Lodera,

                remolqueLlantaDerEje1BrilosPivote: checkedValues.remolqueLlantaDerEje1BrilosPivote,
                remolqueLlantaDerEje1Posicion3: checkedValues.remolqueLlantaDerEje1Posicion3,
                remolqueLlantaDerEje1Posicion4: checkedValues.remolqueLlantaDerEje1Posicion4,
                remolqueLlantaDerEje1MasaYoyo: checkedValues.remolqueLlantaDerEje1MasaYoyo,
                remolqueLlantaDerEje1Rin: checkedValues.remolqueLlantaDerEje1Rin,
                remolqueLlantaDerEje1Lodera: checkedValues.remolqueLlantaDerEje1Lodera,

                remolqueChasisFrontalDerAmortiguador: checkedValues.remolqueChasisFrontalDerAmortiguador,
                remolqueChasisFrontalDerBolsaAire: checkedValues.remolqueChasisFrontalDerBolsaAire,
                remolqueChasisFrontalDerGavilan: checkedValues.remolqueChasisFrontalDerGavilan,
                remolqueChasisFrontalDerMuelle: checkedValues.remolqueChasisFrontalDerMuelle,
                remolqueChasisFrontalDerRotachamber: checkedValues.remolqueChasisFrontalDerRotachamber,
                estatus: estatus,

                remolqueLlantaDerEje2Posicion8Marca: value || null,
                remolqueLlantaDerEje2Posicion8Estatus : selectedValueMarca8 || null,

                //marca8Label: label || null,
                remolqueLlantaDerEje2Posicion7Marca: valueMarca7 || null,  
                remolqueLlantaDerEje2Posicion7Estatus: selectedValueMarca7 || null,

                //marca7Label: labelMarca7 || null,
                remolqueLlantaDerEje1Posicion3Marca: valueMarca3 || null,
                remolqueLlantaDerEje1Posicion3Estatus : selectedValueMarca3 || null,

                //marca3Label: labelMarca3 || null,
                remolqueLlantaDerEje1Posicion4Marca: valueMarca4 || null,
               // marca4Label: labelMarca4 || null,
               remolqueLlantaDerEje1Posicion4Estatus : selectedValueMarca3 || null

                //estatus7: estatus7




            };
            console.log(data);
            const response = await Etapa51(id, data);  
            //window.location.href = `/Etapa3_1?id=${id}`;


        } catch (error: any) {
            console.error('Error en la API:', error.message);
        }
    };


    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };


    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte Derecha</h1>
            <div className="form-check form-switch">
                <input
                    onChange={() => handleCheckboxChange('remolqueLlantaDerJumbo')}
                    className="form-check-input"
                    type="checkbox"
                    id="Intercambio_remolqueLlantaDerJumbo"
                    name="Intercambio_remolqueLlantaDerJumbo"
                    checked={checkedValues.remolqueLlantaDerJumbo}
                />
                <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaDerJumbo">Llantas jumbo?</label>
            </div>
            <div className="row">
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            Chasis trasero Derecho
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueChasisTraseroDerImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcRemolqueChasisTraseroDerFotoUrl || camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueChasisTraseroDerFotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Chasis trasero Derecho</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueChasisTraseroDerFoto"
                                    name="Intercambio_remolqueChasisTraseroDerFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                    onChange={(e) => {
                                        handleImageUploadChasisTraseroDerecho(e);
                                        handleImageChange('Intercambio_remolqueChasisTraseroDerFotoUrl', e);
                                    }}
                                />
                                <input
                                    id="Intercambio_remolqueChasisTraseroDerFotoUrl"
                                    name="Intercambio_remolqueChasisTraseroDerFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerAmortiguador')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerAmortiguador"
                                    name="Intercambio_remolqueChasisTraseroDerAmortiguador"
                                    checked={checkedValues.remolqueChasisTraseroDerAmortiguador}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerAmortiguador">
                                    Amortiguador
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerBolsaAire')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerBolsaAire"
                                    name="Intercambio_remolqueChasisTraseroDerBolsaAire"
                                    checked={checkedValues.remolqueChasisTraseroDerBolsaAire}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerBolsaAire">
                                    Bolsa de aire
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerGavilan')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerGavilan"
                                    name="Intercambio_remolqueChasisTraseroDerGavilan"
                                    checked={checkedValues.remolqueChasisTraseroDerGavilan}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerGavilan">
                                    Matraca
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerMuelle')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerMuelle"
                                    name="Intercambio_remolqueChasisTraseroDerMuelle"
                                    checked={checkedValues.remolqueChasisTraseroDerMuelle}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerMuelle">
                                    Muelle
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerRotachamber')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerRotachamber"
                                    name="Intercambio_remolqueChasisTraseroDerRotachamber"
                                    checked={checkedValues.remolqueChasisTraseroDerRotachamber}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerRotachamber">
                                    Rotachamber
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueLlantaDerEje2Img"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcRemolqueLlantaDerEje2FotoUrl || camara}
                                alt="Placeholder"
                                width="140"
                                height="140" />
                            <canvas id="Intercambio_remolqueLlantaDerEje2FotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Llanta Der eje 2</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueLlantaDerEje2Foto"
                                    name="Intercambio_remolqueLlantaDerEje2Foto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                    onChange={(e) => {
                                        handleImageUploadLlantaDerEje2(e);
                                        handleImageChange('Intercambio_remolqueLlantaDerEje2FotoUrl', e);
                                    }}
                                />
                                <input
                                    id="Intercambio_remolqueLlantaDerEje2FotoUrl"
                                    name="Intercambio_remolqueLlantaDerEje2FotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden />
                                <div id="Intercambio_remolqueLlantaDerEje2Posicion7MarcaLabbel">
                                    <hr />
                                    <label htmlFor="Intercambio_remolqueLlantaDerEje2Posicion7Marca" className="form-label">Marca posicion 7</label>
                                    <Select
                                        value={selectedOptionMarca7 || ''}
                                        inputValue={inputValueMarca7}
                                        onInputChange={handleInputChangeMarca7}
                                        onChange={handleSelectChangeMarca7}
                                        options={marcaOptions7}
                                        isClearable
                                        isSearchable
                                    />

                                    <label htmlFor="Intercambio_remolqueLlantaDerEje2Posicion7Estatus" className="form-label">Estatus posicion 7</label>
                                    <select
                                        value={selectedValueMarca7}
                                        onChange={(e) => handleSelectChangeestatus7(e.target.value)}
                                        id="Intercambio_remolqueLlantaDerEje2Posicion7Estatus"
                                        name="Intercambio_remolqueLlantaDerEje2Posicion7Estatus"
                                        className="form-control mb-3"
                                    >
                                        <option selected>Seleccione</option>
                                        <option value="0">Original</option>
                                        <option value="1">Recapeada</option>
                                    </select>
                                </div>
                                <div id="Intercambio_remolqueLlantaDerEje2Posicion8MarcaLabbel">
                                    <hr />
                                    <label htmlFor="Intercambio_remolqueLlantaDerEje2Posicion8Marca" className="form-label">Marca posicion 8</label>
                                    <Select
                                        value={selectedOption || ''}
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                        onChange={handleSelectChange}
                                        options={marcaOptions}
                                        isClearable
                                        isSearchable
                                    />
                                    <label htmlFor="Intercambio_remolqueLlantaDerEje2Posicion8Estatus" className="form-label">Estatus Posicion 8</label>
                                    <select
                                        value={selectedValueMarca8 || ''}
                                        onChange={(e) => handleSelectChangeMarca8(e.target.value)}
                                        id="Intercambio_remolqueLlantaDerEje2Posicion8Estatus"
                                        name="Intercambio_remolqueLlantaDerEje2Posicion8Estatus"
                                        className="form-control mb-3"
                                    >
                                        <option selected>Seleccione</option>
                                        <option value="0">Original</option>
                                        <option value="1">Recapeada</option>
                                    </select>
                                </div>
                                <hr />
                                <div className="form-check form-switch">
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje2BrilosPivote')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje2BrilosPivote"
                                        name="Intercambio_remolqueLlantaDerEje2BrilosPivote"
                                        checked={checkedValues.remolqueLlantaDerEje2BrilosPivote}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje2BrilosPivote">
                                        Brilos y Pivote
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje2Posicion7')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje2Posicion7"
                                        name="Intercambio_remolqueLlantaDerEje2Posicion7"
                                        checked={checkedValues.remolqueLlantaDerEje2Posicion7}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje2Posicion7">
                                        Llanta Posicion 7
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje2Posicion8')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje2Posicion8"
                                        name="Intercambio_remolqueLlantaDerEje2Posicion8"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje2Posicion8}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje2Posicion8">
                                        Llanta Posicion 8
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje2MasaYoyo')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje2MasaYoyo"
                                        name="Intercambio_remolqueLlantaDerEje2MasaYoyo"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje2MasaYoyo}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje2MasaYoyo">
                                        Masa de yoyo
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje2Rin')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje2Rin"
                                        name="Intercambio_remolqueLlantaDerEje2Rin"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje2Rin}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje2Rin">
                                        Rin
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje2Lodera')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje2Lodera"
                                        name="Intercambio_remolqueLlantaDerEje2Lodera"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje2Lodera}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje2Lodera">
                                        Tiene lodera?
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueLlantaDerEje1Img"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcRemolqueLlantaDerEje1FotoUrl || camara}
                                alt="Placeholder"
                                width="140"
                                height="140" />
                            <canvas id="Intercambio_remolqueLlantaDerEje1FotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Llanta Der eje 1</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueLlantaDerEje1Foto"
                                    name="Intercambio_remolqueLlantaDerEje1Foto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                    onChange={(e) => {
                                        handleImageUploadLlantaDerEje1(e);
                                        handleImageChange('Intercambio_remolqueLlantaDerEje1FotoUrl', e);
                                    }}

                                />
                                <input
                                    id="Intercambio_remolqueLlantaDerEje1FotoUrl"
                                    name="Intercambio_remolqueLlantaDerEje1FotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden />
                                <hr />
                                <label htmlFor="Intercambio_remolqueLlantaDerEje1Posicion3Marca" className="form-label">Marca posicion 3</label>
                                <Select
                                    value={selectedOptionMarca3}
                                    inputValue={inputValueMarca3}
                                    onInputChange={handleInputChangeMarca3}
                                    onChange={handleSelectChangeMarca3}
                                    options={marcaOptions3}
                                    isClearable
                                    isSearchable
                                />

                                <label htmlFor="Intercambio_remolqueLlantaDerEje1Posicion3Estatus" className="form-label">Estatus posicion 3</label>
                                <select id="Intercambio_remolqueLlantaDerEje1Posicion3Estatus" name="Intercambio_remolqueLlantaDerEje1Posicion3Estatus" className="form-control mb-3"
                                      value={selectedValueMarca3}
                                      onChange={(e) => handleSelectChangeestatus3(e.target.value)}
                                >
                                    <option selected>Seleccione</option>
                                    <option value="0">Original</option>
                                    <option value="1">Recapeada</option>
                                </select>

                                <div id="Intercambio_remolqueLlantaDerEje1Posicion4MarcaLabbel">
                                    <hr />
                                    <label htmlFor="Intercambio_remolqueLlantaDerEje1Posicion4Marca" className="form-label">Marca posicion 4</label>
                                    <Select
                                        value={selectedOptionMarca4}
                                        inputValue={inputValueMarca4}
                                        onInputChange={handleInputChangeMarca4}
                                        onChange={handleSelectChangeMarca4}
                                        options={marcaOptions4}
                                        isClearable
                                        isSearchable
                                    />

                                    <label htmlFor="Intercambio_remolqueLlantaDerEje1Posicion4Estatus" className="form-label">Estatus Posicion 4</label>
                                    <select
                                        id="Intercambio_remolqueLlantaDerEje1Posicion4Estatus"
                                        name="Intercambio_remolqueLlantaDerEje1Posicion4Estatus"
                                        className="form-control mb-3"
                                        value={selectedValueMarca4}
                                      onChange={(e) => handleSelectChangeestatus4(e.target.value)}
                                    >
                                        <option selected>Seleccione</option>
                                        <option value="0">Original</option>
                                        <option value="1">Recapeada</option>
                                    </select>
                                </div>

                                <hr />
                                <div className="form-check form-switch">
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje1BrilosPivote')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje1BrilosPivote"
                                        name="Intercambio_remolqueLlantaDerEje1BrilosPivote"
                                        checked={checkedValues.remolqueLlantaDerEje1BrilosPivote}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje1BrilosPivote">
                                        Brilos y Pivote
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje1Posicion3')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje1Posicion3"
                                        name="Intercambio_remolqueLlantaDerEje1Posicion3"
                                        checked={checkedValues.remolqueLlantaDerEje1Posicion3}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje1Posicion3">
                                        Llanta Posicion 3
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje1Posicion4')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje1Posicion4"
                                        name="Intercambio_remolqueLlantaDerEje1Posicion4"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje1Posicion4}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje1Posicion4">
                                        Llanta Posicion 4
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje1MasaYoyo')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje1MasaYoyo"
                                        name="Intercambio_remolqueLlantaDerEje1MasaYoyo"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje1MasaYoyo}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje1MasaYoyo">
                                        Masa de yoyo
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje1Rin')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje1Rin"
                                        name="Intercambio_remolqueLlantaDerEje1Rin"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje1Rin}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje1Rin">
                                        Rin
                                    </label>
                                    <br />
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaDerEje1Lodera')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaDerEje1Lodera"
                                        name="Intercambio_remolqueLlantaDerEje1Lodera"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaDerEje1Lodera}
                                    />
                                    <label
                                        className="form-check-label"
                                        htmlFor="Intercambio_remolqueLlantaDerEje1Lodera">
                                        Tiene lodera?
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            Chasis frontal Derecho
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueChasisFrontalDerImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcRemolqueChasisFrontalDerFotoUrl || camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueChasisFrontalDerFotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Chasis frontal Derecho</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueChasisFrontalDerFoto"
                                    name="Intercambio_remolqueChasisFrontalDerFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    className="form-control"
                                    onChange={(e) => {
                                        handleImageUploadChasisFrontalDerecho(e);
                                        handleImageChange('Intercambio_remolqueChasisFrontalDerFotoUrl', e);
                                    }}
                                />
                                <input
                                    id="Intercambio_remolqueChasisFrontalDerFotoUrl"
                                    name="Intercambio_remolqueChasisFrontalDerFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerAmortiguador')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerAmortiguador"
                                    name="Intercambio_remolqueChasisFrontalDerAmortiguador"
                                    checked={checkedValues.remolqueChasisFrontalDerAmortiguador}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerAmortiguador">
                                    Amortiguador
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerBolsaAire')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerBolsaAire"
                                    name="Intercambio_remolqueChasisFrontalDerBolsaAire"
                                    checked={checkedValues.remolqueChasisFrontalDerBolsaAire}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerBolsaAire">
                                    Bolsa de aire
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerGavilan')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerGavilan"
                                    name="Intercambio_remolqueChasisFrontalDerGavilan"
                                    checked={checkedValues.remolqueChasisFrontalDerGavilan}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerGavilan">
                                    Matraca
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerMuelle')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerMuelle"
                                    name="Intercambio_remolqueChasisFrontalDerMuelle"
                                    checked={checkedValues.remolqueChasisFrontalDerMuelle}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerMuelle">
                                    Muelle
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerRotachamber')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerRotachamber"
                                    name="Intercambio_remolqueChasisFrontalDerRotachamber"
                                    checked={checkedValues.remolqueChasisFrontalDerRotachamber}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerRotachamber">
                                    Rotachamber
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
                <br />
                <footer className="footer">
                    <div className="container-fluid">
                        <div className="row text-muted">
                            <div className="col-6 text-start">
                                <div className="progress">
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                        Inicio
                                    </div>
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Tractor
                                    </div>
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Izquierda
                                    </div>
                                    <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Trasera
                                    </div>
                                    <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Derecha
                                    </div>
                                    <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.9%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                        Firma
                                    </div>
                                </div>
                            </div>
                            <div className="col-6 text-end text-right">
                                <button id="siguienteEtapa" className="btn btn-success" onClick={handleSiguienteClick} disabled={!imagesSelected}>Siguiente</button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );

};

export default Etapas5_1;