import React, { useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown, Collapse } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from "react-icons/fa";
import camara from '../images/photos/photoCamera.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa33 } from "../services/etapa1";


//Etapa 3_3 funcionando , solo falta validar los checkbox , se dejaron igual como funciona actualmente 

type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
};


const Etapas3_3: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };

    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                            <Etapa3_3/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};


const Etapa3_3: React.FC = ( ) => {
    type CheckedValues = {
        [key: string]: boolean;
    };


    const [imageSrcChasisTraseroIzq, setImageSrcChasisTraseroIzq] = useState('');
    const [imageSrcChasisFrontalIzq, setImageSrcChasisFrontalIzq] = useState('');
    const [imagesData, setImagesData] =  useState<{ [key: string]: File | null}>({
        remolqueChasisFrontalIzqFotoUrl: null,
        remolqueChasisTraseroIzqFotoUrl: null
    });

    const [imagesSelected, setImagesSelected] = useState(false);
    useEffect(() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null );
        setImagesSelected(allImagesSelected);
    }, [imagesData]);



    const handleImageChange = (id: string , e: ChangeEvent<HTMLInputElement>)  => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }));
        }
    };
    const handleImageUploadChasisTraseroIzq = async (event: any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcChasisTraseroIzq(imageUrl);
            } catch (error) {
                console.error('Error al cargar la imagen:', error);
            }
        }
    };

    const handleImageUploadChasisFrontalIzq =  async (event : any) => {
        const file = event.target.files[0];
        if (file) {
            try {
                const imageUrl = URL.createObjectURL(file);
                setImageSrcChasisFrontalIzq(imageUrl);

            } catch (error) {
                console.error('Error al cargar la imagen')
            }
           
        }
    };





    const [checkedValues, setCheckedValues] = useState<CheckedValues>({
        remolqueChasisFrontalIzqAmortiguador: false,
        remolqueChasisFrontalIzqBolsaAire: false,
        remolqueChasisFrontalIzqGavilan: false,
        remolqueChasisFrontalIzqMuelle: false,
        remolqueChasisFrontalIzqRotachamber: false,

        remolqueChasisTraseroIzqAmortiguador: false,
        remolqueChasisTraseroIzqBolsaAire: false,
        remolqueChasisTraseroIzqGavilan: false,
        remolqueChasisTraseroIzqMuelle: false,
        remolqueChasisTraseroIzqRotachamber: false
    });
    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [checkboxName]: !prevValues[checkboxName],
        }));
    };


    const handleSiguienteClick = async () => {
        const filenames : {[ key: string]: string | null } = {} ;
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '' ;
        const estatus = 7;

        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)) {
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }

        }

        try {
                const data = {
                    remolqueChasisFrontalIzqFotoUrl: filenames['remolqueChasisFrontalIzqFotoUrl'] || null,
                    remolqueChasisTraseroIzqFotoUrl: filenames['remolqueChasisTraseroIzqFotoUrl'] || null,
                    remolqueChasisFrontalIzqAmortiguador: checkedValues.remolqueChasisFrontalIzqAmortiguador,
                    remolqueChasisFrontalIzqBolsaAire: checkedValues.remolqueChasisFrontalIzqBolsaAire,
                    remolqueChasisFrontalIzqGavilan : checkedValues.remolqueChasisFrontalIzqGavilan,
                    remolqueChasisFrontalIzqMuelle: checkedValues.remolqueChasisFrontalIzqMuelle,
                    remolqueChasisFrontalIzqRotachamber: checkedValues.remolqueChasisFrontalIzqRotachamber,
                    remolqueChasisTraseroIzqAmortiguador: checkedValues.remolqueChasisTraseroIzqAmortiguador,
                    remolqueChasisTraseroIzqBolsaAire: checkedValues.remolqueChasisTraseroIzqBolsaAire,
                    remolqueChasisTraseroIzqGavilan: checkedValues.remolqueChasisTraseroIzqGavilan,
                    remolqueChasisTraseroIzqMuelle: checkedValues.remolqueChasisTraseroIzqMuelle,
                    remolqueChasisTraseroIzqRotachamber: checkedValues.remolqueChasisTraseroIzqRotachamber,
                    estatus: estatus
                };

                console.log(data)
                const response = await Etapa33(id,data)
                setImageSrcChasisFrontalIzq('');
                setImageSrcChasisTraseroIzq('');
                window.location.href = `/Etapa3_4?id=${id}`;

         } catch (error) {

            console.log('Error en la api', error)

         }

    }


    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() *  1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;
            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagem ', error);
                    reject(error);
                });
        });

    };




    return (
        <div className="container-fluid p-0">
            <div className="container-fluid p-0">
                <h1 className="h3 mb-3">Remolque: Parte izquierda</h1>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-12 col-md-6">
                                <div className="card">
                                    <img

                                        src={imageSrcChasisFrontalIzq||camara}
                                        className="img-thumbnail rounded mr-2 mt-2"
                                        alt="Chasis frontal izquierdo"
                                        width="140"
                                        height="140"
                                    />
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Chasis frontal izquierdo</h5>
                                    </div>
                                    <div className="col-sm-10">
                                        <input
                                            onChange={(e) =>{
                                                handleImageUploadChasisFrontalIzq(e);
                                                handleImageChange('remolqueChasisFrontalIzqFotoUrl', e)
                                            }}
                                            type="file"
                                            accept="image/*"
                                            capture
                                            className="form-control"
                                        />
                                        <div className="form-check form-switch">
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqAmortiguador')}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisFrontalIzqAmortiguador"
                                                name="Intercambio_remolqueChasisFrontalIzqAmortiguador"
                                                value="0"
                                                checked={checkedValues.remolqueChasisFrontalIzqAmortiguador}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqAmortiguador">
                                                Amortiguador
                                            </label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqBolsaAire')}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisFrontalIzqBolsaAire"
                                                name="Intercambio_remolqueChasisFrontalIzqBolsaAire"
                                                value="0"
                                                checked={checkedValues.remolqueChasisFrontalIzqBolsaAire}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqBolsaAire">
                                                Bolsa de aire
                                            </label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqGavilan')}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisFrontalIzqGavilan"
                                                name="Intercambio_remolqueChasisFrontalIzqGavilan"
                                                value="0"
                                                checked={checkedValues.remolqueChasisFrontalIzqGavilan}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqGavilan">Gavilan</label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqMuelle')}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisFrontalIzqMuelle"
                                                name="Intercambio_remolqueChasisFrontalIzqMuelle"
                                                value="0"
                                                checked={checkedValues.remolqueChasisFrontalIzqMuelle}
                                            />
                                            <label className='form-check-label' htmlFor="Intercambio_remolqueChasisFrontalIzqMuelle">Muelle</label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqRotachamber')}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisFrontalIzqRotachamber"
                                                name="Intercambio_remolqueChasisFrontalIzqRotachamber"
                                                value="0"
                                                checked={checkedValues.remolqueChasisFrontalIzqRotachamber}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqRotachamber">Rotachamber</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6">
                                <div className="card">
                                    <img
                                        src={imageSrcChasisTraseroIzq || camara}
                                        className="img-thumbnail rounded mr-2 mt-2"
                                        alt="Chasis trasero izquierdo"
                                        width="140"
                                        height="140"
                                    />
                                    <div className="card-header">
                                        <h5 className="card-title mb-0">Chasis trasero izquierdo</h5>
                                    </div>
                                    <div className="col-sm-10">
                                        <input
                                            onChange={(e) => {
                                                handleImageUploadChasisTraseroIzq(e);
                                                handleImageChange('remolqueChasisTraseroIzqFotoUrl', e);
                                            }}
                                            type="file"
                                            accept="image/*"
                                            capture
                                            className="form-control"
                                        />
                                        <div className="form-check form-switch">
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqAmortiguador')}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisTraseroIzqAmortiguador"
                                                name="Intercambio_remolqueChasisTraseroIzqAmortiguador"
                                                value="0"
                                                checked={checkedValues.remolqueChasisTraseroIzqAmortiguador}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqAmortiguador">
                                                Amortiguador
                                            </label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqBolsaAire')}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisTraseroIzqBolsaAire"
                                                name="Intercambio_remolqueChasisTraseroIzqBolsaAire"
                                                value="0"
                                                checked={checkedValues.remolqueChasisTraseroIzqBolsaAire}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqBolsaAire">Bolsa de Aire</label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqGavilan')}
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Intercambio_remolqueChasisTraseroIzqGavilan"
                                                id="Intercambio_remolqueChasisTraseroIzqGavilan"
                                                value="0"
                                                checked={checkedValues.remolqueChasisTraseroIzqGavilan}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqGavilan">Gavilan</label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqMuelle')}
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Intercambio_remolqueChasisTraseroIzqMuelle"
                                                id="Intercambio_remolqueChasisTraseroIzqMuelle"
                                                value="0"
                                                checked={checkedValues.remolqueChasisTraseroIzqMuelle}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqMuelle">Muelle</label>
                                            <br />
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqRotachamber')}
                                                className="form-check-input"
                                                type="checkbox"
                                                name="Intercambio_remolqueChasisTraseroIzqRotachamber"
                                                id="Intercambio_remolqueChasisTraseroIzqRotachamber"
                                                value="0"
                                                checked={checkedValues.remolqueChasisTraseroIzqRotachamber}
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqRotachamber">Rotachamber</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                    Inicio
                                </div>
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Tractor
                                </div>
                                <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Izquierda
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Trasera
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-end text-right">
                            <button id="siguienteEtapa" className="btn btn-success" onClick={handleSiguienteClick} disabled={!imagesSelected} >
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );



};



export default Etapas3_3;