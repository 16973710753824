import React, { Component, ChangeEvent, FormEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { actualizarRecurso } from '../services/etapa1';

//etapa funcionando, probada y funcional

interface State {
  Intercambio_tractoDefensa: boolean;
  Intercambio_tractoCabina: boolean;
  Intercambio_tractoQuintaRueda: boolean;
  Intercambio_tractoTuboEscape: boolean;
  Intercambio_tractoBaseRemolque: boolean;
  Intercambio_tractoTechos: boolean;
  Intercambio_tractoLlantas: boolean;
  Intercambio_tractoTanqueDiesel: boolean;
  Intercambio_tractoTanqueAire: boolean;
  Intercambio_tractoEjeTransmision: boolean;
  Intercambio_tractoMotor: boolean;

}

class TractorPage extends Component<{}, State> {
  constructor(props: {}) {
    super(props);
    this.state = {
      Intercambio_tractoDefensa: true,
      Intercambio_tractoCabina: true,
      Intercambio_tractoQuintaRueda: true,
      Intercambio_tractoTuboEscape: true,
      Intercambio_tractoBaseRemolque: true,
      Intercambio_tractoTechos: true,
      Intercambio_tractoLlantas: true,
      Intercambio_tractoTanqueDiesel: true,
      Intercambio_tractoTanqueAire: true,
      Intercambio_tractoEjeTransmision: true,
      Intercambio_tractoMotor: true,
    };
  }

  handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    this.setState({ [name]: checked } as Pick<State, keyof State>);
  };

  handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    const {
      Intercambio_tractoDefensa,
      Intercambio_tractoCabina,
      Intercambio_tractoQuintaRueda,
      Intercambio_tractoTuboEscape,
      Intercambio_tractoBaseRemolque,
      Intercambio_tractoTechos,
      Intercambio_tractoLlantas,
      Intercambio_tractoTanqueDiesel,
      Intercambio_tractoTanqueAire,
      Intercambio_tractoEjeTransmision,
      Intercambio_tractoMotor,
    } = this.state;
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id') ?? '';
    const estatus = 2;

    const data = {
      Intercambio_tractoDefensa: Intercambio_tractoDefensa ? 1 : 0,
      Intercambio_tractoCabina: Intercambio_tractoCabina ? 1 : 0,
      Intercambio_tractoQuintaRueda: Intercambio_tractoQuintaRueda ? 1 : 0,
      Intercambio_tractoTuboEscape: Intercambio_tractoTuboEscape ? 1 : 0,
      Intercambio_tractoBaseRemolque: Intercambio_tractoBaseRemolque ? 1 : 0,
      Intercambio_tractoTechos: Intercambio_tractoTechos ? 1 : 0,
      Intercambio_tractoLlantas: Intercambio_tractoLlantas ? 1 : 0,
      Intercambio_tractoTanqueDiesel: Intercambio_tractoTanqueDiesel ? 1 : 0,
      Intercambio_tractoTanqueAire: Intercambio_tractoTanqueAire ? 1 : 0,
      Intercambio_tractoEjeTransmision: Intercambio_tractoEjeTransmision ? 1 : 0,
      Intercambio_tractoMotor: Intercambio_tractoMotor ? 1 : 0,
      estatus,
    };

    try {
      console.log(data)
      const response = await actualizarRecurso(id, data);
      window.location.href = `/Etapa2_1?id=${id}`;

    } catch (error: any) {
      console.error('Error en la API:', error.message);
    }


  };

  handleButtonClick = (buttonId: string) => {
    if (buttonId === 'siguienteEtapa') {
      window.location.href = '';
    }
  };

  render() {
    const {
      Intercambio_tractoDefensa,
      Intercambio_tractoCabina,
      Intercambio_tractoQuintaRueda,
      Intercambio_tractoTuboEscape,
      Intercambio_tractoBaseRemolque,
      Intercambio_tractoTechos,
      Intercambio_tractoLlantas,
      Intercambio_tractoTanqueDiesel,
      Intercambio_tractoTanqueAire,
      Intercambio_tractoEjeTransmision,
      Intercambio_tractoMotor,
    } = this.state;

    return (
      <div className="container-fluid p-0">
        <form onSubmit={this.handleSubmit}>
          <h1 className="h3 mb-3">Tractor</h1>
          <div className="row">
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoDefensa"
                      name="Intercambio_tractoDefensa"
                      value="1"
                      checked={Intercambio_tractoDefensa}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoDefensa">
                      Defensa
                    </label>
                    <input id="Intercambio_id" type="text" className="form-control" hidden />
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoCabina"
                      name="Intercambio_tractoCabina"
                      value="1"
                      checked={Intercambio_tractoCabina}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoCabina">
                      Cabina y compartimientos
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoQuintaRueda"
                      name="Intercambio_tractoQuintaRueda"
                      value="1"
                      checked={Intercambio_tractoQuintaRueda}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoQuintaRueda">
                      Quinta rueda
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoTuboEscape"
                      name="Intercambio_tractoTuboEscape"
                      value="1"
                      checked={Intercambio_tractoTuboEscape}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoTuboEscape">
                      Tubo de escape
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoBaseRemolque"
                      name="Intercambio_tractoBaseRemolque"
                      value="1"
                      checked={Intercambio_tractoBaseRemolque}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoBaseRemolque">
                      Base del remolque
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoTechos"
                      name="Intercambio_tractoTechos"
                      value="1"
                      checked={Intercambio_tractoTechos}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoTechos">
                      Techos interno y externo
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoLlantas"
                      name="Intercambio_tractoLlantas"
                      value="1"
                      checked={Intercambio_tractoLlantas}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoLlantas">
                      Llantas
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoTanqueDiesel"
                      name="Intercambio_tractoTanqueDiesel"
                      value="1"
                      checked={Intercambio_tractoTanqueDiesel}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoTanqueDiesel">
                      Tanque de diesel
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoTanqueAire"
                      name="Intercambio_tractoTanqueAire"
                      value="1"
                      checked={Intercambio_tractoTanqueAire}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoTanqueAire">
                      Tanque de aire
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoEjeTransmision"
                      name="Intercambio_tractoEjeTransmision"
                      value="1"
                      checked={Intercambio_tractoEjeTransmision}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoEjeTransmision">
                      Eje de transmisión
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      onChange={this.handleCheckboxChange}
                      className="form-check-input"
                      type="checkbox"
                      id="Intercambio_tractoMotor"
                      name="Intercambio_tractoMotor"
                      value="1"
                      checked={Intercambio_tractoMotor}
                    />
                    <label className="form-check-label" htmlFor="Intercambio_tractoMotor">
                      Motor
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer className="footer">
            <div className="container-fluid">
              <div className="row text-muted">
                <div className="col-6 text-start">
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: '16.6%' }}
                      aria-valuenow={16.6}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      Inicio
                    </div>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '16.6%' }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      Tractor
                    </div>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: '16.6%' }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      Izquierda
                    </div>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: '0%' }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      20%
                    </div>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: '0%' }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      20%
                    </div>
                    <div
                      className="progress-bar bg-warning"
                      role="progressbar"
                      style={{ width: '0%' }}
                      aria-valuenow={0}
                      aria-valuemin={0}
                      aria-valuemax={100}
                    >
                      20%
                    </div>
                  </div>
                </div>
                <div className="col-6 text-end text-right">
                  <button
                    id="siguienteEtapa"
                    className="btn btn-success"
                    onClick={() => this.handleSubmit}
                  >
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </form>
      </div>
    );
  }
}

export default TractorPage;

