import React, { useState, useEffect, ChangeEvent } from 'react';
import camara from '../images/icono foto.avif';
import { Linea, llenado, Operador, Remolque } from '../services/etapa1';
import { Transportista } from '../services/etapa1';
import Select from 'react-select';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';



const FormularioIntercambios: React.FC = () => {

  const [imageSrcLicencia, setImageSrcLicencia] = useState('');
  const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
    licenciaUrl: null
  });

  const [imagesSelected, setImagesSelected] = useState(false);

  useEffect(() => {
    const allImagesSelected = Object.values(imagesData).every(image => image !== null);
    setImagesSelected(allImagesSelected);

  }, [imagesData]);


  const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      setImagesData((prevImagesData) => ({
        ...prevImagesData,
        [id]: files[0],
      }));
    }
  };

  const handleImageUploadLicencia = async (event: any) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const imageUrl = URL.createObjectURL(file);
        setImageSrcLicencia(imageUrl);
      } catch (error) {
        console.error('Error al cargar la imagen');
      }
    };
  };

  // Estados de inicializacion de las variables a usar
  const [tipoOperacion, setTipoOperacion] = useState<string>('');
  const [tipoMovimiento, setTipoMovimiento] = useState<string>('');
  const [estatusRemolque, setEstatusRemolque] = useState<string>('');
  const [comentario2, setComentario] = useState<string>('');
  const [nombreOperador, setOperadorNombre] = useState<string>('');
  const [idOperador, setIdOperador] = useState<number>(0);
  const [licencia, setLicencia] = useState<string>('');
  const [transportista, setTransportista] = useState<string>('');
  const [unidad, setUnidad] = useState<string>('');
  const [idUnidad, setIdUnidad] = useState<number>(0);
  const [Linea, setLinea] = useState<string>('');
  const [idLinea, setIdLinea] = useState<number>(0);
  const [remolque, setRemolque] = useState<string>('');
  const [idRemolque, setIdRemolque] = useState<number>(0);
  const [idTransportista, setIdTransportista] = useState<number>(0);

  //fill operadores
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [operatorOptions, setOperatorOptions] = useState<any[]>([]);
  const [selectedValue, setSelectedValue] = useState('');

  // fill transportistas y sus unidades
  const [selectedOptionTransportista, setSelectOptionTransportista] = useState<any>(null);
  const [inputValueTransportista, setInputValueTransportista] = useState('');
  const [transportistaOptions, setTransportistaOptions] = useState<any[]>([]);
  const [selectedTransportistaUnits, setSelectedTransportistaUnits] = useState<any[]>([]);
  const [selectedOptionUnidad, setSelectedOptionUnidad] = useState<any>(null);
  const [inputValueUnidad, setInputValueUnidad] = useState('');
  const [unidadOptions, setUnidadOptions] = useState<any[]>([]);

  //fill lineas y sus remolques
  const [selectedOptionLinea, setSelectedOptionLinea] = useState<any>(null);
  const [inputValueLinea, setInputValueLinea] = useState('');
  const [lineaOptions, setLineaOptions] = useState<any[]>([]);
  const [selectedLineaUnits, setSelectedLineaUnits] = useState<any[]>([]);
  const [selectedOptionRemolque, setSelectedOptionRemolque] = useState<any>(null);
  const [inputValueRemolque, setInputValueRemolque] = useState('');
  const [remolqueOptions, setRemolqueOptions] = useState<any[]>([]);

  const loadOptionsLinea = async (inputValueLinea: string) => {
    try {
      const response = await Remolque(inputValueLinea);
      const lineaOptions = response?.Linea.map((Linea: any) => ({
        value: Linea.id,
        label: Linea.nombre,
        Remolque: Linea.Remolque,
      }));
      return lineaOptions;
    } catch (error) {
      console.error('Error fetching linea options', error);
      return [];
    }
  };
  useEffect(() => {
    const fetchOptionsLinea = async () => {
      const optionsLinea = await loadOptionsLinea(inputValueLinea);
      setLineaOptions(optionsLinea);
    };
    fetchOptionsLinea();
  }, [inputValueLinea]);
  const handleInputLineaChange = (inputValueLinea: string) => {
    setInputValueLinea(inputValueLinea);
  };
  const handleSelectLineaChange = (selectedOptionLinea: any) => {
    setSelectedOptionLinea(selectedOptionLinea);

    if (selectedOptionLinea) {
      setLinea(selectedOptionLinea.label);
      setIdLinea(selectedOptionLinea.value);
      setSelectedLineaUnits(selectedOptionLinea.Remolque || []);
    } else {
      setLinea('');
      setIdLinea(0);
      setSelectedLineaUnits([]);
      setRemolqueOptions([]);
      setSelectedOptionRemolque(null);
    }
  };
  useEffect(() => {
    if (selectedLineaUnits.length > 0) {
      setRemolqueOptions(
        selectedLineaUnits.map((Remolque: any) => ({
          value: Remolque.idRemolque,
          label: Remolque.clave,
        }))
      );
    } else {
      setRemolqueOptions([]);
    }
  }, [selectedLineaUnits]);
  const handleInputRemolqueChange = (inputValueRemolque: string) => {
    setInputValueRemolque(inputValueRemolque);
  };
  const handleSelectRemolqueChange = (selectedOptionRemolque: any) => {
    setSelectedOptionRemolque(selectedOptionRemolque);

    if (selectedOptionRemolque) {
      setRemolque(selectedOptionRemolque.label);
      setIdRemolque(selectedOptionRemolque.value);
    } else {
      setRemolque('');
      setIdRemolque(0);
    }
  };
  const loadOptionsTransportista = async (inputValueTransportista: string) => {
    try {
      const response = await Transportista(inputValueTransportista);
      const transportistaOptions = response?.Transportista.map((transportista: any) => ({
        value: transportista.idTransportista,
        label: transportista.nombre,
        Unidad: transportista.Unidad,
      }));
      return transportistaOptions;
    } catch (error) {
      console.error('Error fetching transportista options', error);
      return [];
    }
  };
  useEffect(() => {
    const fetchOptions = async () => {
      const options = await loadOptionsTransportista(inputValueTransportista);
      setTransportistaOptions(options);
    };
    fetchOptions();
  }, [inputValueTransportista]);
  const handleInputTransportistaChange = (inputValueTransportista: string) => {
    setInputValueTransportista(inputValueTransportista);
  }
  const handleSelectTransportistaChange = (selectedOptionTransportista: any) => {
    setSelectOptionTransportista(selectedOptionTransportista);
    if (selectedOptionTransportista) {
      setTransportista(selectedOptionTransportista.label);
      setIdTransportista(selectedOptionTransportista.value);
      setSelectedTransportistaUnits(
        selectedOptionTransportista.Unidad || []
      );
    } else {
      setTransportista('');
      setIdTransportista(0);
      setSelectedTransportistaUnits([]);
    }
  };
  const loadOptionsUnidad = async (inputValueUnidad: string) => {
    try {
      const response = await Transportista(inputValueUnidad);
      const unidadOptions = response?.unidad.map((unidad: any) => ({
        value: unidad.id,
        label: unidad.clave

      }));
      return unidadOptions;

    } catch (error) {
      console.error('Error fetching unidad options:', error)
      return [];
    }
  };
  useEffect(() => {
    // Si hay unidades del transportista seleccionado, llena el unidadOptions con ellas
    if (selectedTransportistaUnits.length > 0) {
      setUnidadOptions(
        selectedTransportistaUnits.map((unidad: any) => ({
          value: unidad.idUnidad,
          label: unidad.claveUnidad,
        }))
      );
    } else {
      // Si no hay unidades del transportista seleccionado, reinicia el unidadOptions a un arreglo vacío
      setUnidadOptions([]);
    }
  }, [selectedTransportistaUnits]);
  const handleInputUnidadChange = (inputValueUnidad: string) => {
    setInputValueUnidad(inputValueUnidad);
  };
  const handleSelectUnidadChange = (selectedOptionUnidad: any) => {
    setSelectedOptionUnidad(selectedOptionUnidad);


    if (selectedOptionUnidad) {
      setUnidad(selectedOptionUnidad.label);
      setIdUnidad(selectedOptionUnidad.value);
    } else {
      setUnidad('');
      setIdUnidad(0);
    }


  };
  const loadOptions = async (inputValue: any) => {
    try {
      const response = await Operador(inputValue);
      const operatorOptions = response?.operador.map((operador: any) => ({
        value: operador.id,
        label: operador.value,
      }));
      return operatorOptions;
    } catch (error) {
      console.error('Error fetching operator options:', error);
      return [];
    }
  };
  useEffect(() => {
    const fetchOptions = async () => {
      const options = await loadOptions(inputValue);
      setOperatorOptions(options);
    };

    fetchOptions();
  }, [inputValue]);
  const handleInputChange = async (inputValue: any) => {
    setInputValue(inputValue);
    setSelectedValue(inputValue);
  };
  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);
    setSelectedValue(''); // Reiniciar el valor de selectedValue cuando se selecciona una opción
  };

  const handleSubmit = async (event: React.FormEvent) => {
    const filenames: { [key: string]: string | null } = {};

    event.preventDefault();

    for (const key in imagesData) {
      if (imagesData.hasOwnProperty(key)) {
        const file = imagesData[key];
        if (file) {
          const filename = await uploadImageToFirebaseStorage(file);
          filenames[key] = filename;
        }
      }
    }

    const data = {
      tipoOperacion,
      tipoMovimiento,
      estatusRemolque,
      comentario2,
      nombreOperador: selectedOption ? selectedOption.label : inputValue,
      idOperador: selectedOption ? selectedOption.value : null,
      licencia : filenames['licenciaUrl'] || null,
      transportista,
      idTransportista,
      unidad,
      idUnidad,
      idLinea,
      Linea,
      remolque,
      idRemolque,

    };

    try {
      console.log(data);
      const response = await llenado(data);
      if (response) {
        const responseData = await response;
        const id = responseData.json[0].id;
        window.location.href = `/Etapa2?id=${id}`;
        console.log('Respuesta de la API:', id);
      } else {
        console.error('Error en la API:', 'Error en la respuesta del POST');
      }
    } catch (error: any) {
      console.error('Error en la API:', error.message);
    }

    setTipoOperacion('');
    setTipoMovimiento('');
    setEstatusRemolque('');
    setComentario('');
    setOperadorNombre('');
    setIdOperador(0);
    setSelectedOption(null);
    setSelectedOptionUnidad(null);
    setSelectOptionTransportista(null);
    setLicencia('');
    setTransportista('');
    setIdTransportista(0);
    setUnidad('');
    setIdUnidad(0);
    setLinea('');
    setRemolque('');
    setIdRemolque(0);
  };



  const nombreUsuario = localStorage.getItem('usuario');

  const inputNombreUsuario = document.getElementById('Intercambio_usuarioNombre');

  if (inputNombreUsuario instanceof HTMLInputElement) {
    inputNombreUsuario.value = nombreUsuario || ''; // Si nombreUsuario es null, establece el valor a una cadena vacía
  }


  const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const storageRef = firebase.storage().ref();

      const timestamp = Date.now();
      const randomNumber = Math.floor(Math.random() * 1000);
      const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

      const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

      imageRef
        .put(file)
        .then(() => {
          resolve(uniqueFilename);
        })
        .catch((error) => {
          console.error('Error al subir la imagen:', error);
          reject(error);
        });
    });
  };





  return (

    <div className="container-fluid p-0">
      <h1 className="h3 mb-3">Registro</h1>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Intercambio de remolque</h5>
            </div>
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label className="form-label">Usuario</label>
                  <input
                    id="Intercambio_usuarioNombre"
                    type="text"
                    className="form-control"
                    disabled
                  />
                  <input id="Intercambio_idUsuario" type="text" className="form-control" hidden />
                </div>
                <div className="mb-3">
                  <label htmlFor="Intercambio_tipoOperacion" className="form-label">
                    Tipo de operacion
                  </label>
                  <select
                    id="Intercambio_tipoOperacion"
                    name="Intercambio_tipoOperacion"
                    className="form-control mb-3"
                    value={tipoOperacion}
                    onChange={(e) => setTipoOperacion(e.target.value)}
                  >
                    <option value="">Seleccione</option>
                    <option value="0">Entrada</option>
                    <option value="1">Salida</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="Intercambio_tipoMovimiento" className="form-label">
                    Tipo de movimiento
                  </label>
                  <select
                    id="Intercambio_tipoMovimiento"
                    name="Intercambio_tipoMovimiento"
                    className="form-control mb-3"
                    value={tipoMovimiento}
                    onChange={(e) => setTipoMovimiento(e.target.value)}
                  >
                    <option value="">Seleccione</option>
                    <option value="0">Exportacion</option>
                    <option value="1">Importacion</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="Intercambio_estatusRemolque" className="form-label">
                    Estatus
                  </label>
                  <select
                    id="Intercambio_estatusRemolque"
                    name="Intercambio_estatusRemolque"
                    className="form-control mb-3"
                    value={estatusRemolque}
                    onChange={(e) => setEstatusRemolque(e.target.value)}
                  >
                    <option value="">Seleccione</option>
                    <option value="0">Cargado</option>
                    <option value="1">Vacio</option>
                    <option value="2">Racks</option>
                  </select>
                </div>
                <div className="mb-3">
                  <h6 className="card-subtitle text-muted">Comentario</h6>
                  <textarea
                    id="Intercambio_comentario2"
                    name="Intercambio_comentario2"
                    className="form-control"
                    placeholder="Escribe.."
                    rows={1}
                    value={comentario2}
                    onChange={(e) => setComentario(e.target.value)}
                  ></textarea>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Operador</h5>
            </div>
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="Intercambio_operadorNombre" className="col-form-label col-sm-2 text-sm-left">
                    Operador
                  </label>
                  <div className="col-sm-10">
                    <Select
                      value={selectedOption}
                      inputValue={inputValue}
                      onInputChange={(input, { action }) => {
                        if (action === 'input-change') {
                          setInputValue(input);
                        }
                      }}
                      onChange={handleSelectChange}
                      options={operatorOptions}
                      isClearable
                      isSearchable
                    />
                    {selectedOption && (
                      <div className="autocomplete-value">{selectedOption.label}</div>
                    )}

                    <input id="Intercambio_idOperador" name="Intercambio_idOperador" type="text" className="form-control" hidden />
                  </div>
                </div>
                <div className="card-body">
                  <img
                    className="img-thumbnail rounded mr-2 mb-2"
                    src={imageSrcLicencia || camara}
                    alt="Licencia"
                    width="140"
                    height="140"
                  />
                  <canvas id="Intercambio_licenciaCanvas" hidden></canvas>
                  <div className="col-sm-10">
                    <h6 className="card-subtitle text-muted">Licencia</h6>
                    <input
                      onChange={(e) => {
                        handleImageUploadLicencia(e),
                          handleImageChange('licenciaUrl', e)
                      }}
                      id="Intercambio_licencia"
                      name="Intercambio_licencia"
                      type="file"
                      accept="image/*"
                      capture
                      className="form-control"
                    />
                    <input id="Intercambio_licenciaUrl"
                      name="Intercambio_licenciaUrl"
                      type="text"
                      className="form-control"
                      hidden />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="mb-4"></div>
        <div className="col-12 col-xl-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Tracto</h5>
            </div>
            <div className="card-header">
              <form>
                <div className="card-header">
                  <h5 className="card-title">Tracto</h5>
                </div>
                <div className="card-header">
                  <div className="mb-3">
                    <label htmlFor="Intercambio_idTransportista" className="form-label">
                      Transportista
                    </label>
                    <Select
                      value={selectedOptionTransportista}
                      inputValue={inputValueTransportista}
                      onInputChange={handleInputTransportistaChange}
                      onChange={handleSelectTransportistaChange}
                      options={transportistaOptions}
                      isClearable
                      isSearchable
                    />

                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="Intercambio_unidad">
                      Unidad
                    </label>
                    <Select
                      value={selectedOptionUnidad}
                      inputValue={inputValueUnidad}
                      onInputChange={handleInputUnidadChange}
                      onChange={handleSelectUnidadChange}
                      options={unidadOptions}
                      isClearable
                      isSearchable
                    />
                    <input
                      id="Intercambio_idUnidad"
                      name="Intercambio_idUnidad"
                      type="text"
                      className="form-control"
                      hidden
                    />
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title">Remolque</h5>
            </div>
            <div className="card-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="Intercambio_idLinea" className="form-label">
                    Línea
                  </label>
                  <Select
                    options={lineaOptions}
                    value={selectedOptionLinea}
                    onChange={handleSelectLineaChange}
                    onInputChange={handleInputLineaChange}
                    inputValue={inputValueLinea}
                  />

                </div>
                <div className="mb-3">
                  <label className="form-label" htmlFor="Intercambio_unidad">
                    Remolque
                  </label>
                  <Select
                    options={remolqueOptions}
                    value={selectedOptionRemolque}
                    onChange={handleSelectRemolqueChange}
                    onInputChange={handleInputRemolqueChange}
                    inputValue={inputValueRemolque}
                  />
                  <input
                    id="Intercambio_idRemolque"
                    name="Intercambio_idRemolque"
                    type="text"
                    className="form-control"
                    hidden
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <div className="progress">
                <div className="progress-bar" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                  Inicio
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Tractor
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  30%
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  20%
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  20%
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  20%
                </div>
              </div>
            </div>
            <div className="col-6 text-end text-right">
              <button onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                handleSubmit(event);
              }} id="siguienteEtapa" className="btn btn-success" type="submit" disabled={!imagesSelected}>
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};


export default FormularioIntercambios;
