import React, { useEffect, useState, ComponentType } from 'react';
import { useNavigate } from 'react-router-dom';

type RequireAuthProps = {
  WrappedComponent: ComponentType<any>;
};

const RequireAuth: React.FC<RequireAuthProps> = ({ WrappedComponent }) => {
  const navigate = useNavigate();
  const [isTokenValid, setTokenValid] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const expirationTime = localStorage.getItem('expirationTime');

    if (!token || !expirationTime) {
      setTokenValid(false);
      navigate('/');
    } else {
      const currentTime = new Date().getTime();

      if (currentTime > parseInt(expirationTime)) {
        // Token ha expirado
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        setTokenValid(false);
        navigate('/');
      }
    }
  }, [navigate]);

  if (!WrappedComponent || !isTokenValid) {
    return null;
  }

  return <WrappedComponent />;
};

export default RequireAuth;
