import React, { useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Card, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa5_0 } from "../services/etapa1";


//Etapa funcionando al 100 % , minimizando el cache y sanitizando la salida al cambiar de etapa


type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
}

const Etapas5: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };


    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa5 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};



const Etapa5 = () => {
    type CheckedValues = {
        [key: string]: boolean;
    };


    const [imageSrcTraseroDerecho, setImageSrcTraseroDerecho] = useState('');
    const [imageSrcFrenteDerecho, setImageSrcFrenteDerecho] = useState('');
    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        Intercambio_remolqueCostadoTraseroDerechoFotoUrl : null,
        Intercambio_remolqueCostadoFrenteDerechoFotoUrl : null
    });
    const [imagesSelected, setImagesSelected] = useState(false);
    useEffect(() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);
    }, [imagesData]);

    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }))
        }
    };

    const [checkedValues, setCheckedValues] = useState<CheckedValues>({
        remolqueFondoPlagas: true,
        remolquePisoPlagas: true,
        remolqueTechoPlagas: true,
        remolqueDerLuces: true,
        remolqueDerGaliboFrontal: true,
        remolqueDerParedPlagas: true,
        remolqueDerIzqParedPlagas: true,
        remolqueDerPatin: true,

        remolqueCuartoLadoDer: true,
        LoderaDer: true,
        remolqueLucesDerP2: true,
        luzBarcoDer: true,
        rompevientosDer: true
    });

    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [checkboxName]: !prevValues[checkboxName],
        }));
    };


    const handleImageUploadTraseroDerecho = (event : any) =>{
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcTraseroDerecho(imageUrl);
        }

    };

    const handleImageUploadFrenteDerecho = (event:any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcFrenteDerecho(imageUrl);
        }
    };

    const handleSiguienteClick = async () => {
        const filenames : {[key: string]: string | null } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 12;

        for (const key in imagesData){
            if (imagesData.hasOwnProperty(key)){
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }
        };

        try {
            const data = {
                Intercambio_remolqueCostadoTraseroDerechoFotoUrl : filenames.Intercambio_remolqueCostadoTraseroDerechoFotoUrl,
                Intercambio_remolqueCostadoFrenteDerechoFotoUrl : filenames.Intercambio_remolqueCostadoFrenteDerechoFotoUrl,
                Intercambio_estatus:estatus,
                Intercambio_remolquePisoPlagas : checkedValues.remolquePisoPlagas,
                Intercambio_remolqueTechoPlagas : checkedValues.remolqueTechoPlagas,
                Intercambio_remolqueDerLuces : checkedValues.remolqueDerLuces,
                Intercambio_remolqueDerGaliboFrontal : checkedValues.remolqueDerGaliboFrontal,
                Intercambio_remolqueDerParedPlagas : checkedValues.remolqueDerParedPlagas,
                Intercambio_remolqueDerIzqParedPlagas : checkedValues.remolqueDerIzqParedPlagas,
                Intercambio_remolqueDerPatin : checkedValues.remolqueDerPatin 

            }
            console.log(data)
            const response = await Etapa5_0(id,data);
            setImageSrcFrenteDerecho('');
            setImageSrcTraseroDerecho('');
            window.location.href = `/Etapa5_1?id=${id}`;
        } catch (error : any){
            console.error('Error en la api', error.message);

        }

    }


    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };


    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte derecha</h1>

            <div className="row">
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            Trasero derecho
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueCostadoTraseroDerechoImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={ imageSrcTraseroDerecho ||camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueCostadoTraseroDerechoFotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Trasero Derecho</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueCostadoTraseroDerechoFoto"
                                    name="Intercambio_remolqueCostadoTraseroDerechoFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadTraseroDerecho(e);
                                        handleImageChange('Intercambio_remolqueCostadoTraseroDerechoFotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueCostadoTraseroDerechoFotoUrl"
                                    name="Intercambio_remolqueCostadoTraseroDerechoFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            Frente derecho
                        </div>
                        <div className="card-body">
                            <img
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={imageSrcFrenteDerecho ||camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueCostadoFrenteDerechoFotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Frente Derecho</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueCostadoFrenteDerechoFoto"
                                    name="Intercambio_remolqueCostadoFrenteDerechoFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadFrenteDerecho(e);
                                        handleImageChange('Intercambio_remolqueCostadoFrenteDerechoFotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueCostadoFrenteDerechoFotoUrl"
                                    name="Intercambio_remolqueCostadoFrenteDerechoFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Costado derecho del Remolque P1</h5>
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    onChange={() => handleCheckboxChange('remolqueFondoPlagas')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueFondoPlagas"
                                    name="Intercambio_remolqueFondoPlagas"
                                    checked={checkedValues.remolqueFondoPlagas}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueFondoPlagas">
                                    ¿El fondo de la caja/contendedor esta libre de plagas?
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolquePisoPlagas')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolquePisoPlagas"
                                    name="Intercambio_remolquePisoPlagas"
                                    checked={checkedValues.remolquePisoPlagas}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolquePisoPlagas">
                                    El piso esta libre de plagas?
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueTechoPlagas')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueTechoPlagas"
                                    name="Intercambio_remolqueTechoPlagas"
                                    checked={checkedValues.remolqueTechoPlagas}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueTechoPlagas">
                                    El techo esta libre de plagas?
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueDerLuces')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueDerLuces"
                                    name="Intercambio_remolqueDerLuces"
                                    checked={checkedValues.remolqueDerLuces}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueDerLuces">
                                    Luces en buen estado?
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueDerGaliboFrontal')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueDerGaliboFrontal"
                                    name="Intercambio_remolqueDerGaliboFrontal"
                                    checked={checkedValues.remolqueDerGaliboFrontal}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueDerGaliboFrontal">
                                    Luz galibo derecha frontal sup
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueDerParedPlagas')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueDerParedPlagas"
                                    name="Intercambio_remolqueDerParedPlagas"
                                    checked={checkedValues.remolqueDerParedPlagas}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueDerParedPlagas">
                                    pared derecha libre de plagas?
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueDerIzqParedPlagas')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueDerIzqParedPlagas"
                                    name="Intercambio_remolqueDerIzqParedPlagas"
                                    checked={checkedValues.remolqueDerIzqParedPlagas}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueDerIzqParedPlagas">
                                    Pared izquierda libre de plagas?
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueDerPatin')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueDerPatin"
                                    name="Intercambio_remolqueDerPatin"
                                    checked={checkedValues.remolqueDerPatin}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueDerPatin">
                                    Patin derecho
                                </label>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="card-header">
                                <h5 className="card-title mb-0">Costado derecho del Remolque P2</h5>
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    onChange={() => handleCheckboxChange('remolqueCuartoLadoDer')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueCuartoLadoDer"
                                    name="Intercambio_remolqueCuartoLadoDer"
                                    checked={checkedValues.remolqueCuartoLadoDer}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueCuartoLadoDer">
                                    Cuarto lado derecho
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('LoderaDer')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_LoderaDer"
                                    name="Intercambio_LoderaDer"
                                    checked={checkedValues.LoderaDer}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_LoderaDer">
                                    Lodera derecha
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueLucesDerP2')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueLucesDerP2"
                                    name="Intercambio_remolqueLucesDerP2"
                                    checked={checkedValues.remolqueLucesDerP2}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueLucesDerP2">
                                    Luces en buen estado?
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('luzBarcoDer')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_luzBarcoDer"
                                    name="Intercambio_luzBarcoDer"
                                    checked={checkedValues.luzBarcoDer}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_luzBarcoDer">
                                    Luz de barco derecha
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('rompevientosDer')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_rompevientosDer"
                                    name="Intercambio_rompevientosDer"
                                    checked={checkedValues.rompevientosDer}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_rompevientosDer">
                                    Rompevientos derecho
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                    Inicio
                                </div>
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Tractor
                                </div>
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Izquierda
                                </div>
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Trasera
                                </div>
                                <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Derecha
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.9%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Firma
                                </div>
                            </div>
                        </div>
                        <div className="col-6 text-end text-right">
                            <button onClick={handleSiguienteClick} id="siguienteEtapa" className="btn btn-success" disabled={!imagesSelected}>Siguiente</button>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};





export default Etapas5;