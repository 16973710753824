export async function loginUser(credentials: any) {
  const response = await fetch('https://huskyapp.dxxpress.net/api/Authentication/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials)
  });

  if (response.ok) {
    const data = await response.json();

    // Guardar el token y la hora de expiración en el localStorage
    const expirationTime = new Date().getTime() + 9 * 60 * 60 * 1000; // 9 horas desde ahora
    localStorage.setItem('token', data.token);
    localStorage.setItem('expirationTime', expirationTime.toString());
    localStorage.setItem('idUsuario', data.idUsuario);
    localStorage.setItem('usuario', data.usuario);
        console.log(data)



    return data.token; // Devuelve el token de la respuesta
  } else {
    throw new Error('Credenciales inválidas');
  }
}



function validateToken() {
  const token = localStorage.getItem('token');
  const expirationTime = localStorage.getItem('expirationTime');

  if (token && expirationTime) {
    const currentTime = new Date().getTime();

    if (currentTime > parseInt(expirationTime)) {
      // Token ha expirado
      localStorage.removeItem('token');
      localStorage.removeItem('expirationTime');
    } else {
      // Token válido, actualizar la hora de expiración
      const newExpirationTime = currentTime + 9 * 60 * 60 * 1000; // 9 horas desde ahora
      localStorage.setItem('expirationTime', newExpirationTime.toString());
    }
  }
}
