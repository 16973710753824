import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown, Collapse } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa32 } from '../services/etapa1';


// Etapa 32 faltan recibir la marca de las llantas 
// y manejar bien los tipo marcas y estatus

type Etapa3_2Props = {
    isLlantasJumbo: boolean;
    activeTab: number;
    handleLlantasJumboChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleTabChange: (tabIndex: number) => void;
    selectedImage: string; // Agregar la propiedad selectedImage al tipo
    handleImageChange: (imageName: string) => void; // Agregar la propiedad handleImageChange al tipo
};
const Etapa3_2: React.FC<Etapa3_2Props> = ({
    isLlantasJumbo,
    activeTab,
    handleLlantasJumboChange,
    handleTabChange,
    selectedImage// Asegúrate de recibir la prop aquí
    // Asegúrate de recibir la prop aquí
}) => {


    type CheckedValues = {
        [key: string]: boolean;
    };
    const [imageSrc, setImageSrc] = useState('');
    const [imageSrcLlantaIzqEje1, setImageSrcLlantaIzqEje1] = useState('');
    const [imageSrcLlantaIzqEje2, setImageSrcLlantaIzqEje2] = useState('');
    const [imageSrcChasisTraseroIzq, setImageSrcChasisTraseroIzq] = useState('');
    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        remolqueLlantaIzqEje2FotoUrl: null,
        remolqueLlantaIzqEje1FotoUrl: null,
        remolqueChasisTraseroIzqFotoUrl: null,
        remolqueChasisFrontalIzqFotoUrl: null
    });




    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }));
        }
    };





    const [checkedValues, setCheckedValues] = useState<CheckedValues>({
        remolqueLlantaIzqJumbo: false,
        remolqueChasisFrontalIzqAmortiguador: true,
        remolqueChasisFrontalIzqBolsaAire: true,
        remolqueChasisFrontalIzqGavilan: true,
        remolqueChasisFrontalIzqMuelle: true,
        remolqueChasisFrontalIzqRotachamber: true,
        remolqueLlantaIzqEje1BrilosPivote: true,
        remolqueLlantaIzqEje1Posicion1: true,
        remolqueLlantaIzqEje1Posicion2: true,
        remolqueLlantaIzqEje1MesaYoyo: true,
        remolqueLlantaIzqEje1Rin: true,
        remolqueLlantaIzqEje1Lodera: true,
        remolqueLlantaIzqEje2BrilosPivote: true,
        remolqueLlantaIzqEje2Posicion5: true,
        remolqueLlantaIzqEje2Posicion6: true,
        remolqueLlantaIzqEje2MesaYoyo: true,
        remolqueLlantaIzqEje2Rin: true,
        remolqueLlantaIzqEje2Lodera: true,
        remolqueChasisTraseroIzqAmortiguador: true,
        remolqueChasisTraseroIzqBolsaAire: true,
        remolqueChasisTraseroIzqGavilan: true,
        remolqueChasisTraseroIzqMuelle: true,
        remolqueChasisTraseroIzqRotachamber: true,
    });
    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [checkboxName]: !prevValues[checkboxName],
        }));
    };

    const handleImageUpload = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrc(imageUrl);
        }
    };

    const handleImageUploadLlantaizqeje1 = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcLlantaIzqEje1(imageUrl);
        }
    };


    const handleImageUploadLlantaizqeje2 = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcLlantaIzqEje2(imageUrl);
        }
    };

    const handleImageUploadChasisTraseroIzq = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcChasisTraseroIzq(imageUrl);
        }
    };
    let selectedOptionPosicion5Marca: string | null = null;
    let selectedOptionPosicion5Estatus: string | null = null;
    let selectedOptionPosicion6Marca: string | null = null;
    let selectedOptionPosicion6Estatus: string | null = null;
    let selectedOptionPosicion1Marca: string | null = null;
    let selectedOptionPosicion1Estatus: string | null = null;
    let selectedOptionPosicion2Marca: string | null = null;
    let selectedOptionPosicion2Estatus: string | null = null;
    
    function getSelectedValue(selectId: string): string | null {
        const selectElement = document.getElementById(selectId) as HTMLSelectElement | null;
        return selectElement ? selectElement.value : null;
    }
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje2Posicion5Marca')?.addEventListener('change', () => {
        selectedOptionPosicion5Marca = getSelectedValue('Intercambio_remolqueLlantaIzqEje2Posicion5Marca');
    });
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje2Posicion5Estatus')?.addEventListener('change', () => {
        selectedOptionPosicion5Estatus = getSelectedValue('Intercambio_remolqueLlantaIzqEje2Posicion5Estatus');
    });
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje2Posicion6Marca')?.addEventListener('change', () => {
        selectedOptionPosicion6Marca = getSelectedValue('Intercambio_remolqueLlantaIzqEje2Posicion6Marca');
    });
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje2Posicion6Estatus')?.addEventListener('change', () => {
        selectedOptionPosicion6Estatus = getSelectedValue('Intercambio_remolqueLlantaIzqEje2Posicion6Estatus');
    });
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje1Posicion1Marca')?.addEventListener('change', () => {
        selectedOptionPosicion1Marca = getSelectedValue('Intercambio_remolqueLlantaIzqEje1Posicion1Marca');
    });
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje1Posicion1Estatus')?.addEventListener('change', () => {
        selectedOptionPosicion1Estatus = getSelectedValue('Intercambio_remolqueLlantaIzqEje1Posicion1Estatus');
    });
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje1Posicion2Marca')?.addEventListener('change', () => {
        selectedOptionPosicion2Marca = getSelectedValue('Intercambio_remolqueLlantaIzqEje1Posicion2Marca');
    });
    
    document.getElementById('Intercambio_remolqueLlantaIzqEje1Posicion2Estatus')?.addEventListener('change', () => {
        selectedOptionPosicion2Estatus = getSelectedValue('Intercambio_remolqueLlantaIzqEje1Posicion2Estatus');
    });


    
 



    const handleSiguienteClick = async () => {
        const filenames: { [key: string]: string | null } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 6;

        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)) {
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }
        }

        try {
            const data = {
                
                    Intercambio_remolqueLlantaIzqJumbo: checkedValues.remolqueLlantaIzqJumbo,
                    Intercambio_remolqueChasisFrontalIzqAmortiguador: checkedValues.remolqueChasisFrontalIzqAmortiguador,
                    Intercambio_remolqueChasisFrontalIzqBolsaAire: checkedValues.remolqueChasisFrontalIzqBolsaAire,
                    Intercambio_remolqueChasisFrontalIzqGavilan: checkedValues.remolqueChasisFrontalIzqGavilan,
                    Intercambio_remolqueChasisFrontalIzqMuelle: checkedValues.remolqueChasisFrontalIzqMuelle,
                    Intercambio_remolqueChasisFrontalIzqRotachamber: checkedValues.remolqueChasisFrontalIzqRotachamber,
                    Intercambio_remolqueLlantaIzqEje2BrilosPivote: checkedValues.remolqueLlantaIzqEje2BrilosPivote,
                    Intercambio_remolqueLlantaIzqEje2Posicion5: checkedValues.remolqueLlantaIzqEje2Posicion5,
                    Intercambio_remolqueLlantaIzqEje2Posicion6: checkedValues.remolqueLlantaIzqEje2Posicion6,
                    Intercambio_remolqueLlantaIzqEje2MesaYoyo: checkedValues.remolqueLlantaIzqEje2MesaYoyo,
                    Intercambio_remolqueLlantaIzqEje2Rin: checkedValues.remolqueLlantaIzqEje2Rin,
                    Intercambio_remolqueLlantaIzqEje2Lodera: checkedValues.remolqueLlantaIzqEje2Lodera,
                    Intercambio_remolqueLlantaIzqEje2Posicion5Marca: selectedOptionPosicion5Marca,
                    Intercambio_remolqueLlantaIzqEje2Posicion5Estatus: selectedOptionPosicion5Estatus,
                    Intercambio_remolqueLlantaIzqEje2Posicion6Marca: selectedOptionPosicion6Marca,
                    Intercambio_remolqueLlantaIzqEje2Posicion6Estatus: selectedOptionPosicion6Estatus,
                    Intercambio_remolqueLlantaIzqEje1BrilosPivote: checkedValues.remolqueLlantaIzqEje1BrilosPivote,
                    Intercambio_remolqueLlantaIzqEje1Posicion1: checkedValues.remolqueLlantaIzqEje1Posicion1,
                    Intercambio_remolqueLlantaIzqEje1Posicion2: checkedValues.remolqueLlantaIzqEje1Posicion2,
                    Intercambio_remolqueLlantaIzqEje1MesaYoyo: checkedValues.remolqueLlantaIzqEje1MesaYoyo,
                    Intercambio_remolqueLlantaIzqEje1Rin: checkedValues.remolqueLlantaIzqEje1Rin,
                    Intercambio_remolqueLlantaIzqEje1Lodera: checkedValues.remolqueLlantaIzqEje1Lodera,
                    Intercambio_remolqueLlantaIzqEje1Posicion1Marca: selectedOptionPosicion1Marca,
                    Intercambio_remolqueLlantaIzqEje1Posicion1Estatus: selectedOptionPosicion1Estatus,
                    Intercambio_remolqueLlantaIzqEje1Posicion2Marca: selectedOptionPosicion2Marca,
                    Intercambio_remolqueLlantaIzqEje1Posicion2Estatus: selectedOptionPosicion2Estatus,
                    Intercambio_remolqueChasisTraseroIzqAmortiguador: checkedValues.remolqueChasisTraseroIzqAmortiguador,
                    Intercambio_remolqueChasisTraseroIzqBolsaAire: checkedValues.remolqueChasisTraseroIzqBolsaAire,
                    Intercambio_remolqueChasisTraseroIzqGavilan: checkedValues.remolqueChasisTraseroIzqGavilan,
                    Intercambio_remolqueChasisTraseroIzqMuelle: checkedValues.remolqueChasisTraseroIzqMuelle,
                    Intercambio_remolqueChasisTraseroIzqRotachamber: checkedValues.remolqueChasisTraseroIzqRotachamber,
                    Intercambio_estatus: estatus,
                    Intercambio_remolqueLlantaIzqEje2FotoUrl: filenames.remolqueLlantaIzqEje2FotoUrl || null,
                    Intercambio_remolqueLlantaIzqEje1FotoUrl: filenames.remolqueLlantaIzqEje1FotoUrl || null,
                    Intercambio_remolqueChasisTraseroIzqFotoUrl: filenames.remolqueChasisTraseroIzqFotoUrl || null,
                    Intercambio_remolqueChasisFrontalIzqFotoUrl: filenames.remolqueChasisFrontalIzqFotoUrl || null
                  
                  
            };

            console.log('Payload a enviar:', data);
            const response = await Etapa32(id, data);
        } catch (error: any) {
            console.error('Error en la API:', error.message);
        }
    };


    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };


    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte izquierda</h1>

            <div className="row">
                <div className="col-12">
                    <div className="card-body">
                        <div className="card">
                            <div className="card">
                                <div className="form-check form-switch">
                                    <input
                                        onChange={() => handleCheckboxChange('remolqueLlantaIzqJumbo')}
                                        className="form-check-input"
                                        type="checkbox"
                                        id="Intercambio_remolqueLlantaIzqJumbo"
                                        name="Intercambio_remolqueLlantaIzqJumbo"
                                        value="1"
                                        checked={checkedValues.remolqueLlantaIzqJumbo}
                                    />
                                    <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqJumbo">Llantas jumbo?</label>

                                </div>
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Chasis frontal izquierdo</h5>
                                </div>
                                <div style={{ margin: '10px 0', marginLeft: '10px' }}>
                                    <img

                                        id="Intercambio_remolqueChasisFrontalIzqImg"
                                        className="img-thumbnail rounded mr-2 mb-2"
                                        src={imageSrc || camara}
                                        alt="Placeholder"
                                        width="140"
                                        height="140"
                                    />
                                </div>

                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => {
                                        handleImageUpload(e);
                                        handleImageChange('remolqueChasisFrontalIzqFotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <canvas id="Intercambio_remolqueChasisFrontalIzqFotoCanvas" hidden></canvas>
                                <div className="col-sm-10 d-flex flex-wrap">
                                    <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqAmortiguador')}
                                            type="checkbox"
                                            id="Intercambio_remolqueChasisFrontalIzqAmortiguador"
                                            name="Intercambio_remolqueChasisFrontalIzqAmortiguador"
                                            value="1"
                                            checked={checkedValues.remolqueChasisFrontalIzqAmortiguador}
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqAmortiguador">
                                            Amortiguador
                                        </label>
                                    </div>

                                    <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqBolsaAire')}
                                            type="checkbox"
                                            id="Intercambio_remolqueChasisFrontalIzqBolsaAire"
                                            name="Intercambio_remolqueChasisFrontalIzqBolsaAire"
                                            value="1"
                                            checked={checkedValues.remolqueChasisFrontalIzqBolsaAire}
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqBolsaAire">
                                            Bolsa de aire
                                        </label>
                                    </div>

                                    <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqGavilan')}
                                            type="checkbox"
                                            id="Intercambio_remolqueChasisFrontalIzqGavilan"
                                            name="Intercambio_remolqueChasisFrontalIzqGavilan"
                                            value="1"
                                            checked={checkedValues.remolqueChasisFrontalIzqGavilan}
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqGavilan">
                                            Gavilan
                                        </label>
                                    </div>

                                    <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqMuelle')}
                                            type="checkbox"
                                            id="Intercambio_remolqueChasisFrontalIzqMuelle"
                                            name="Intercambio_remolqueChasisFrontalIzqMuelle"
                                            value="1"
                                            checked={checkedValues.remolqueChasisFrontalIzqMuelle}
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqMuelle">
                                            Muelle
                                        </label>
                                    </div>

                                    <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueChasisFrontalIzqRotachamber')}
                                            type="checkbox"
                                            id="Intercambio_remolqueChasisFrontalIzqRotachamber"
                                            name="Intercambio_remolqueChasisFrontalIzqRotachamber"
                                            value="1"
                                            checked={checkedValues.remolqueChasisFrontalIzqRotachamber}
                                            className="form-check-input"
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalIzqRotachamber">
                                            <span className="mr-2">Rotachamber</span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Llanta izq eje 1</h5>
                                </div>
                                <div className="card-body">
                                    <img
                                        id="Intercambio_remolqueLlantaIzqEje1Img"
                                        className="img-thumbnail rounded mr-2 mb-2"
                                        src={imageSrcLlantaIzqEje1 || camara}
                                        alt="Placeholder"
                                        width="140"
                                        height="140"
                                    />

                                    <canvas id="Intercambio_remolqueLlantaIzqEje1FotoCanvas" hidden></canvas>

                                    <label htmlFor="Intercambio_remolqueLlantaIzqEje1Foto" className="form-label">Foto de la llanta</label>
                                    <input
                                          onChange={(e) => {
                                            handleImageUploadLlantaizqeje1(e);
                                            handleImageChange('remolqueLlantaIzqEje1FotoUrl', e);
                                        }} 
                                        id="Intercambio_remolqueLlantaIzqEje1Foto"
                                        name="Intercambio_remolqueLlantaIzqEje1Foto"
                                        type="file"
                                        accept="image/*"
                                        capture
                                        className="form-control"
                                    />

                                    <label htmlFor="Intercambio_remolqueLlantaIzqEje1Posicion1Marca" className="form-label">Marca posición 1</label>
                                    <select
                                        id="Intercambio_remolqueLlantaIzqEje1Posicion1Marca"
                                        name="Intercambio_remolqueLlantaIzqEje1Posicion1Marca"
                                        className="form-control mb-3"
                                    >
                                        {/* Agrega opciones aquí */}
                                    </select>

                                    <label htmlFor="Intercambio_remolqueLlantaIzqEje1Posicion1Estatus" className="form-label">Estatus Posición 1</label>
                                    <select
                                        id="Intercambio_remolqueLlantaIzqEje1Posicion1Estatus"
                                        name="Intercambio_remolqueLlantaIzqEje1Posicion1Estatus"
                                        className="form-control mb-3"
                                    >
                                        <option selected>Seleccione</option>
                                        <option value="0">Original</option>
                                        <option value="1">Recapeada</option>
                                    </select>

                                    <label htmlFor='Intercambio_remolqueLlantaIzqEje1Posicion2Marca' className='form-label'>Marca Posicion 2</label>
                                    <select
                                        id='Intercambio_remolqueLlantaIzqEje1Posicion2Marca'
                                        name='Intercambio_remolqueLlantaIzqEje1Poaicion2Marca'
                                        className='form-control mb-3'>

                                    </select>
                                    <label htmlFor='Intercambio_remolqueLlantaIzqEje1Posicion2Estatus' className='form-label'>Estatus Posicion 2</label>
                                    <select
                                        id='Intercambio_remolqueLlantaIzqEje1Posicion2Estatus'
                                        name='Intercambio_remolqueLlantaIzqEje1Posicion2Estatus'
                                        className='form-control mb-3'
                                    >
                                        <option selected>Seleccione</option>
                                        <option value="0">Original</option>
                                        <option value="1">Recapeada</option>
                                    </select>

                                    <div className="form-check form-switch">

                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje1BrilosPivote')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje1BrilosPivote"
                                            name="Intercambio_remolqueLlantaIzqEje1BrilosPivote"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje1BrilosPivote}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje1BrilosPivote">Birlos y pivote</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje1Posicion1')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje1Posicion1"
                                            name="Intercambio_remolqueLlantaIzqEje1Posicion1"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje1Posicion1}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje1Posicion1">Llanta Posicion 1</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje1Posicion2')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje1Posicion2"
                                            name="Intercambio_remolqueLlantaIzqEje1Posicion2"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje1Posicion2}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje1Posicion2">Llanta Posicion 2</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje1MesaYoyo')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje1MesaYoyo"
                                            name="Intercambio_remolqueLlantaIzqEje1MesaYoyo"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje1MesaYoyo}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje1MesaYoyo">Mesa de yoyo</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje1Rin')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje1Rin"
                                            name="Intercambio_remolqueLlantaIzqEje1Rin"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje1Rin}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje1Rin">Rin</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje1Lodera')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje1Lodera"
                                            name="Intercambio_remolqueLlantaIzqEje1Lodera"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje1Lodera}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje1Lodera">Tiene lodera?</label>
                                    </div>
                                </div>
                            </div>




                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Llanta izq eje 2</h5>
                                </div>
                                <div className="card-body">
                                    <img
                                        id="Intercambio_remolqueLlantaIzqEje1Img"
                                        className="img-thumbnail rounded mr-2 mb-2"
                                        src={imageSrcLlantaIzqEje2 || camara}
                                        alt="Placeholder"
                                        width="140"
                                        height="140"
                                    />
                                    <canvas id="Intercambio_remolqueLlantaIzqEje2FotoCanvas" hidden></canvas>
                                    <label htmlFor="Intercambio_remolqueLlantaIzqEje2Foto" className="form-label">Foto de la llanta</label>
                                    <input
                                        onChange={(e) => {
                                            handleImageUploadLlantaizqeje2(e);
                                            handleImageChange('remolqueLlantaIzqEje2FotoUrl', e);
                                        }} 
                                        id="Intercambio_remolqueLlantaIzqEje2Foto"
                                        name="Intercambio_remolqueLlantaIzqEje2Foto"
                                        type="file"
                                        accept="image/*"
                                        capture
                                        className="form-control"
                                    />


                                    <label htmlFor="Intercambio_remolqueLlantaIzqEje2Posicion5Marca" className="form-label">Marca posición 5</label>
                                    <select
                                        id="Intercambio_remolqueLlantaIzqEje2Posicion5Marca"
                                        name="Intercambio_remolqueLlantaIzqEje2Posicion5Marca"
                                        className="form-control mb-3"
                                    >
                                        {/* Agregar opciones aquí */}
                                    </select>
                                    <label htmlFor='Intercambio_remolqueLlantaIzqEje2Posicion5Estatus' className='form-label'>Estatus Posicion 5</label>
                                    <select
                                        id="Intercambio_remolqueLlantaIzqEje2Posicion5Estatus"
                                        name="Intercambio_remolqueLlantaIzqEje2Posicion5Estatus"
                                        className="form-control mb-3"
                                    >
                                        <option selected>Seleccione</option>
                                        <option value="0">Original</option>
                                        <option value="1">Recapeada</option>
                                    </select>

                                    <label htmlFor="Intercambio_remolqueLlantaIzqEje2Posicion6Marca" className="form-label">Marca posición 6</label>
                                    <select
                                        id="Intercambio_remolqueLlantaIzqEje2Posicion6Marca"
                                        name="Intercambio_remolqueLlantaIzqEje2Posicion6Marca"
                                        className="form-control mb-3"
                                    >
                                        {/* Agregar opciones aquí */}
                                    </select>
                                    <label htmlFor='Intercambio_remolqueLlantaIzqEje2Posicion6Estatus' className='form-label'>Estatus Posicion 6</label>
                                    <select
                                        id="Intercambio_remolqueLlantaIzqEje2Posicion6Estatus"
                                        name="Intercambio_remolqueLlantaIzqEje2Posicion6Estatus"
                                        className="form-control mb-3"
                                    >
                                        <option selected>Seleccione</option>
                                        <option value="0">Original</option>
                                        <option value="1">Recapeada</option>
                                    </select>
                                    <div className="form-check form-switch">
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje2BrilosPivote')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje2BrilosPivote"
                                            name="Intercambio_remolqueLlantaIzqEje2BrilosPivote"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje2BrilosPivote}

                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje2BrilosPivote">Birlos y Pivote</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje2Posicion5')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje2Posicion5"
                                            name="Intercambio_remolqueLlantaIzqEje2Posicion5"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje2Posicion5}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje2Posicion5">Llanta Posicion 5</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje2Posicion6')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje2Posicion6"
                                            name="Intercambio_remolqueLlantaIzqEje2Posicion6"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje2Posicion6}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje2Posicion6">Llanta Posicion 6</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje2MesaYoyo')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje2MesaYoyo"
                                            name="Intercambio_remolqueLlantaIzqEje2MesaYoyo"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje2MesaYoyo}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje2MesaYoyo">Mesa de yoyo</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje2Rin')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje2Rin"
                                            name="Intercambio_remolqueLlantaIzqEje2Rin"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje2Rin}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje2Rin">Rin</label>
                                        <br />
                                        <input
                                            onChange={() => handleCheckboxChange('remolqueLlantaIzqEje2Lodera')}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="Intercambio_remolqueLlantaIzqEje2Lodera"
                                            name="Intercambio_remolqueLlantaIzqEje2Lodera"
                                            value="1"
                                            checked={checkedValues.remolqueLlantaIzqEje2Lodera}
                                        />
                                        <label className="form-check-label" htmlFor="Intercambio_remolqueLlantaIzqEje2Lodera">Tiene lodera?</label>


                                    </div>




                                </div>
                            </div>


                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Chasis trasero izquierdo</h5>
                                </div>

                                <div className='card-body'>
                                    <img
                                        id="Intercambio_remolqueChasisTraseroIzqFoto"
                                        className="img-thumbnail rounded mr-2 mb-2"
                                        src={imageSrcChasisTraseroIzq || camara}
                                        alt="Placeholder"
                                        width="140"
                                        height="140"
                                    />
                                    <canvas id="Intercambio_remolqueChasisTraseroIzqFotoCanvas" hidden></canvas>
                                    <input
                                        onChange={(e) => {
                                            handleImageUploadChasisTraseroIzq(e);
                                            handleImageChange('remolqueChasisTraseroIzqFotoUrl', e);
                                        }}
                                        id="Intercambio_remolqueChasisTraseroIzqFoto"
                                        name="Intercambio_remolqueChasisTraseroIzqFoto"
                                        type="file"
                                        accept="image/*"
                                        capture
                                        className="form-control"
                                    />
                                    <div className="col-sm-10 d-flex flex-wrap">
                                        <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqAmortiguador')}
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisTraseroIzqAmortiguador"
                                                name="Intercambio_remolqueChasisTraseroIzqAmortiguador"
                                                value="1"
                                                checked={checkedValues.remolqueChasisTraseroIzqAmortiguador}
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqAmortiguador">
                                                Amortiguador
                                            </label>
                                        </div>

                                        <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqBolsaAire')}
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisTraseroIzqBolsaAire"
                                                name="Intercambio_remolqueChasisTraseroIzqBolsaAire"
                                                value="1"
                                                checked={checkedValues.remolqueChasisTraseroIzqBolsaAire}
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqBolsaAire">
                                                Bolsa de aire
                                            </label>
                                        </div>

                                        <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqGavilan')}
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisTraseroIzqGavilan"
                                                name="Intercambio_remolqueChasisTraseroIzqGavilan"
                                                value="1"
                                                checked={checkedValues.remolqueChasisTraseroIzqGavilan}
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqGavilan">
                                                Gavilan
                                            </label>
                                        </div>

                                        <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqMuelle')}
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisTraseroIzqMuelle"
                                                name="Intercambio_remolqueChasisTraseroIzqMuelle"
                                                value="1"
                                                checked={checkedValues.remolqueChasisTraseroIzqMuelle}
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqMuelle">
                                                Muelle
                                            </label>
                                        </div>

                                        <div className="form-check mb-2 mr-4" style={{ padding: '0 25px' }}>
                                            <input
                                                onChange={() => handleCheckboxChange('remolqueChasisTraseroIzqRotachamber')}
                                                type="checkbox"
                                                id="Intercambio_remolqueChasisTraseroIzqRotachamber"
                                                name="Intercambio_remolqueChasisTraseroIzqRotachamber"
                                                value="1"
                                                checked={checkedValues.remolqueChasisTraseroIzqRotachamber}
                                                className="form-check-input"
                                            />
                                            <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroIzqRotachamber">
                                                <span className="mr-2">Rotachamber</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="footer">
                        <div className="container-fluid">
                            <div className="row text-muted">
                                <div className="col-6 text-start">
                                    <div className="progress">
                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                            Inicio
                                        </div>
                                        <div className="progress-bar bg-success" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                            Tractor
                                        </div>
                                        <div className="progress-bar" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                            Izquierda
                                        </div>
                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '16.6%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                            Trasera
                                        </div>
                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                            20%
                                        </div>
                                        <div className="progress-bar bg-warning" role="progressbar" style={{ width: '0%' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                            20%
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6 text-end text-right">
                                    <button id="siguienteEtapa" className="btn btn-success" onClick={handleSiguienteClick}>
                                        Siguiente
                                    </button>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
};




type NewPageProps = {
    wrappedComponent: React.ComponentType<any>;
};

const Etapas3_2: React.FC<NewPageProps> = ({ wrappedComponent: WrappedComponent }) => {
    const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };

    const [isLlantasJumbo, setIsLlantasJumbo] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<number>(1);

    const handleLlantasJumboChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLlantasJumbo(event.target.checked);
    };

    const handleTabChange = (tabIndex: number) => {
        setActiveTab(tabIndex);
    };

    const [selectedImage, setSelectedImage] = useState<string>('');

    // Función para manejar el cambio de imagen seleccionada
    const handleImageChange = (imageName: string) => {
        setSelectedImage(imageName);
    };

    return (
        <div>
            <Navbar bg="dark" variant="dark" expand="lg" className="fixed-top">
                <Navbar.Brand>
                    <img src={usuario} alt="User Logo" height="50" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id="user-dropdown">
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className="container-fluid" style={{ height: '100vh' }}>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="content" style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa3_2
                                    isLlantasJumbo={isLlantasJumbo}
                                    activeTab={activeTab}
                                    handleLlantasJumboChange={handleLlantasJumboChange}
                                    handleTabChange={handleTabChange}
                                    selectedImage={selectedImage}
                                    handleImageChange={handleImageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default Etapas3_2;
