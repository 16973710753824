import React, { useState, useEffect } from 'react';
import { loginUser } from '../services/authService';
import logo from '../images/dxlogo.svg';
import { Button, Card, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css'; 

import '../styles/styles.css';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [token, setToken] = useState('');

  const handleLogin = async () => {
    setErrorMessage('');

    // Validar campos de usuario y contraseña
    if (username.trim() === '' || password.trim() === '') {
      setErrorMessage('Por favor, ingresa el usuario y la contraseña.');
      return;
    }

    try {
      const newToken = await loginUser({ username, password });

      // Validar si las credenciales son válidas
      if (!newToken) {
        setErrorMessage('Usuario o contraseña incorrecta');
        return;
      }

      // Calcular la hora de expiración del token (8 horas desde ahora)
      const expirationTime = new Date().getTime() + 8 * 60 * 60 * 1000;

      // Guardar el token y la hora de expiración en el localStorage
      localStorage.setItem('token', newToken);
      localStorage.setItem('expirationTime', expirationTime.toString());

      setToken(newToken);

      console.log('Token:', newToken);
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      const storedToken = localStorage.getItem('token');
      const expirationTime = localStorage.getItem('expirationTime');

      if (storedToken && expirationTime) {
        const currentTime = new Date().getTime();

        if (currentTime > parseInt(expirationTime)) {
          // Token ha expirado
          localStorage.removeItem('token');
          localStorage.removeItem('expirationTime');
          setToken('');
        } else {
          // Token válido
          setToken(storedToken);
        }
      }
    };

    checkTokenExpiration();
  }, []);

  useEffect(() => {
    if (token) {
      window.location.href = '/Home';
    }
  }, [token]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleLogin();
  };


  const cardBodyStyle = {
    border: '1px solid #ddd', 
    borderRadius: '8px', 
    padding: '20px', 
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', 
    backgroundColor: 'white', 
    color: '#333', 
  };

  return (
    <div className="login-page" style={{ backgroundColor: 'white', minHeight: '100vh' }}>
      <div className="container d-flex justify-content-center align-items-center" style={{ backgroundColor: 'white', display: 'none' }}>
          <Card.Body style={cardBodyStyle}>
            <div className="text-center">
              <img src={logo} className="App-logo-extra-small" alt="logo" />
            </div>
            <div className="mt-3">
              {errorMessage && (
                <div className="alert alert-danger" role="alert">
                  {errorMessage}
                </div>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-2">
                  <Form.Control
                    id="usuario"
                    type="text"
                    placeholder="Usuario"
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-2">
                  <Form.Control
                    id="password"
                    type="password"
                    placeholder="Contraseña"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>

                <Form.Group controlId="formBasicCheckbox" className="mb-2">
                  <p className="small">
                    <a className="text-primary" href="#!">
                      ¿Olvidaste la contraseña?
                    </a>
                  </p>
                </Form.Group>

                <div className="d-grid">
                  <Button variant="primary" type="submit" disabled={!username || !password}>
                    Entrar
                  </Button>
                </div>
              </Form>
            </div>
          </Card.Body>
      </div>
    </div>
  );

};

export default Login;
