import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown, Collapse } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import Etapa2 from './etapa2';

type NewPageProps = {
  WrappedComponent: React.ComponentType<any>;
};

const NewPage: React.FC<NewPageProps> = ({ WrappedComponent }) => {
  const [selectedMenuItem, setSelectedMenuItem] = useState<string>('');
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);


  const handleToggleMenu = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationTime');
    window.location.href = '/';
  };

  return (
    <div>
      <Navbar bg="dark" variant="dark" expand="lg" className="fixed-top">
        <Navbar.Brand>
          <img src={usuario} alt="User Logo" height="50" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggleMenu}>
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Collapse in={!menuCollapsed}>
          <Nav>
            <NavDropdown title="Usuario" id="user-dropdown">
              <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      <div style={{ paddingTop: '80px' }}>
        <div className="container-fluid" style={{ height: '100vh' }}>
          <div className="row">
            <div className="col-lg-12">
              <div className="content" style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                {selectedMenuItem === 'add' ? <Etapa2 /> : <Etapa2 />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPage;
