import React, { useState, useEffect, ChangeEvent } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown, Collapse } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa4_0 } from '../services/etapa1';


//Etapa funcionando al 100% ,disminullendo el cache a lo menor posible , sanitizando el cambio de etapa

type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
};

const Etapas4: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };

    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa4 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


const Etapa4 = () => {
    type CheckedValues = {
        [key: string]: boolean;
    };

    const [imageSrcPuertas, setImageSrcPuertas] = useState('');
    const [imageSrcPlacas, setImageSrcPlacas] = useState('');
    const [imageSrcSello1, setImagesSrcSello1] = useState('');
    const [imageSrcSello2, setImageSrcSello2] = useState('');
    const [imageSrcSello3, setImageSrcSello3] = useState('');


    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        Intercambio_remolquePuertasFotoUrl: null,
        Intercambio_remolquePlacasFotoUrl: null,
        Intercambio_remolqueSello1FotoUrl: null,
        Intercambio_remolqueSello2FotoUrl: null,
        Intercambio_remolqueSello3FotoUrl: null
    });

    const [imagesSelected, setImagesSelected] = useState(false);
    useEffect (() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);
    } ,  [imagesData]);

    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }))
        }
    };






    const [checkedValues, setCheckedValues] = useState<CheckedValues>({
        remolquePuertasBisagrasTornillos: true,
        remolquePuertasDefensa: true,
        remolquePuertasLuzGaliboSupTraseras: true,
        remolquePuertasPlafonDerecho: true,
        remolquePuertasPlafonIzquierdo: true,
        remolquePlacasLuzPlaca: true,
        remolquePlacasPlaca: true,
        remolqueSello1Sello: true,
        remolqueSello1Segurida: true,
        remolqueSello2Escotilla: true,
        remolqueSello2Sello: true,
        remolqueSello2Seguridad: true,
        remolqueSello2Vvtt: true
    });
    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [checkboxName]: !prevValues[checkboxName],
        }));
    };

    const handleImageUploadPuertas = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcPuertas(imageUrl);
        }
    };

    const handleImageUploadPlacas = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcPlacas(imageUrl);
        }
    };

    const handleImageUploadSello1 = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImagesSrcSello1(imageUrl);
        }
    };

    const handleImageUploadSello2 = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcSello2(imageUrl);
        }
    };

    const handleImageUploadSello3 = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcSello3(imageUrl);
        }
    };


    const textareaElement: HTMLTextAreaElement | null = document.getElementById("Intercambio_PlacasTxt") as HTMLTextAreaElement | null;
    let PlacasTxt: string | null = null;
    
    if (textareaElement !== null) {
      PlacasTxt = textareaElement.value;
    }
    
    const textareaElementSello1: HTMLTextAreaElement | null = document.getElementById("Intercambio_Sello1Txt") as HTMLTextAreaElement | null;
    let Sello1Txt: string | null = null;
    
    if (textareaElementSello1 !== null) {
      Sello1Txt = textareaElementSello1.value;
    }
    
    const textareaElementSello2: HTMLTextAreaElement | null = document.getElementById("Intercambio_Sello2Txt") as HTMLTextAreaElement | null;
    let Sello2Txt: string | null = null;
    
    if (textareaElementSello2 !== null) {
      Sello2Txt = textareaElementSello2.value;
    }
    
    const textareaElementSello3: HTMLTextAreaElement | null = document.getElementById("Intercambio_Sello3Txt") as HTMLTextAreaElement | null;
    let Sello3Txt: string | null = null;
    
    if (textareaElementSello3 !== null) {
      Sello3Txt = textareaElementSello3.value;
    }
    

    const handleSiguienteClick = async () => {
        const filenames: { [key: string]: string | null } = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 8;

        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)) {
                const file = imagesData[key];
                if (file) {
                    const filename = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;
                }
            }
        }

        try {
            const data = {
                remolquePuertasFotoUrl: filenames.Intercambio_remolquePuertasFotoUrl,
                remolquePlacasFotoUrl: filenames.Intercambio_remolquePlacasFotoUrl,
                remolqueSello1FotoUrl: filenames.Intercambio_remolqueSello1FotoUrl,
                remolqueSello2FotoUrl: filenames.Intercambio_remolqueSello2FotoUrl,
                remolqueSello3FotoUrl: filenames.Intercambio_remolqueSello3FotoUrl,
                estatus,
                remolquePuertasBisagrasTornillos: checkedValues.remolquePuertasBisagrasTornillos,
                remolquePuertasDefensa: checkedValues.remolquePuertasDefensa,
                remolquePuertasLuzGaliboSupTraseras: checkedValues.remolquePuertasLuzGaliboSupTraseras,
                remolquePuertasPlafonDerecho: checkedValues.remolquePuertasPlafonDerecho,
                remolquePuertasPlafonIzquierdo: checkedValues.remolquePuertasPlafonIzquierdo,
                remolquePlacasLuzPlaca: checkedValues.remolquePlacasLuzPlaca,
                remolquePlacasPlaca: checkedValues.remolquePlacasPlaca,
                remolqueSello1Sello: checkedValues.remolqueSello1Sello,
                remolqueSello1Segurida: checkedValues.remolqueSello1Segurida,
                remolqueSello2Escotilla: checkedValues.remolqueSello2Escotilla,
                remolqueSello2Sello: checkedValues.remolqueSello2Sello,
                remolqueSello2Seguridad: checkedValues.remolqueSello2Seguridad,
                remolqueSello2Vvtt: checkedValues.remolqueSello2Vvtt,
                PlacasTxt: PlacasTxt,
                Sello1Txt: Sello1Txt,
                Sello2Txt: Sello2Txt,
                Sello3Txt: Sello3Txt,



            };

            console.log(data);
            const response = await Etapa4_0(id,data);
            setImageSrcPlacas('');
            setImageSrcPuertas('');
            setImageSrcSello2('');
            setImageSrcSello3('');
            setImagesSrcSello1('');
            window.location.href = `/Etapa4_1?id=${id}`;
        } catch (error: any) {
            console.error('Error en la api :', error.message);
        }
    }

    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };






    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte izquierda</h1>

            <div className="col-12 col-md-6">
                <div className="card">
                    <div className="card-body">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Puertas</h5>
                        </div>
                        <img
                            id="Intercambio_remolquePuertasImg"
                            className="img-thumbnail rounded mr-2 mb-2"
                            src={imageSrcPuertas || camara}
                            alt="Placeholder"
                            width="140"
                            height="140"
                        />
                        <canvas id="Intercambio_remolquePuertasFotoCanvas" hidden></canvas>
                        <div className="col-sm-10">
                            <input
                                id="Intercambio_remolquePuertasFoto"
                                name="Intercambio_remolquePuertasFoto"
                                type="file"
                                accept="image/*"
                                capture
                                onChange={(e) => {
                                    handleImageUploadPuertas(e);
                                    handleImageChange('Intercambio_remolquePuertasFotoUrl', e);
                                }}
                                className="form-control"
                            />
                            <input
                                id="Intercambio_remolquePuertasFotoUrl"
                                name="Intercambio_remolquePuertasFotoUrl"
                                type="text"
                                className="form-control"
                                hidden
                            />
                            <div className="form-check form-switch">
                                <input
                                    onChange={() => handleCheckboxChange('remolquePuertasBisagrasTornillos')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolquePuertasBisagrasTornillos"
                                    name="Intercambio_remolquePuertasBisagrasTornillos"
                                    value="1"
                                    checked={checkedValues.remolquePuertasBisagrasTornillos}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolquePuertasBisagrasTornillos">
                                    Bisagras y tornillos
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolquePuertasDefensa')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolquePuertasDefensa"
                                    name="Intercambio_remolquePuertasDefensa"
                                    value="1"
                                    checked={checkedValues.remolquePuertasDefensa}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolquePuertasDefensa">
                                    Defensa
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolquePuertasLuzGaliboSupTraseras')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolquePuertasLuzGaliboSupTraseras"
                                    name="Intercambio_remolquePuertasLuzGaliboSupTraseras"
                                    value="1"
                                    checked={checkedValues.remolquePuertasLuzGaliboSupTraseras}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolquePuertasLuzGaliboSupTraseras">
                                    Luz galibo sup traseras
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolquePuertasPlafonDerecho')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolquePuertasPlafonDerecho"
                                    name="Intercambio_remolquePuertasPlafonDerecho"
                                    value="1"
                                    checked={checkedValues.remolquePuertasPlafonDerecho}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolquePuertasPlafonDerecho">
                                    Plafones derechos
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolquePuertasPlafonIzquierdo')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolquePuertasPlafonIzquierdo"
                                    name="Intercambio_remolquePuertasPlafonIzquierdo"
                                    value="1"
                                    checked={checkedValues.remolquePuertasPlafonIzquierdo}
                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolquePuertasPlafonIzquierdo">
                                    Plafones izquierdos
                                </label>
                                <br />
                            </div>
                        </div>
                    </div>

                </div>
                <br />
            
            <div className="card">
                <div className="card-body">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Placas</h5>
                    </div>
                    <img
                        id="Intercambio_remolquePlacasImg"
                        className="img-thumbnail rounded mr-2 mb-2"
                        src={imageSrcPlacas || camara}
                        alt="Placeholder"
                        width="140"
                        height="140"
                    />
                    <canvas id="Intercambio_remolquePlacasFotoCanvas" hidden></canvas>
                    <div className="col-sm-10">
                        <input
                            id="Intercambio_remolquePlacasFoto"
                            name="Intercambio_remolquePlacasFoto"
                            type="file"
                            accept="image/*"
                            capture
                            onChange={(e) => {
                                handleImageUploadPlacas(e);
                                handleImageChange('Intercambio_remolquePlacasFotoUrl', e);
                            }}
                            className="form-control"
                        />
                        <input
                            id="Intercambio_remolquePlacasFotoUrl"
                            name="Intercambio_remolquePlacasFotoUrl"
                            type="text"
                            className="form-control"
                            hidden
                        />
                        <input
                            id="Intercambio_PlacasTxt"
                            name="Intercambio_PlacasTxt"
                            type="text"
                            className="form-control"
                            placeholder="Placas"
                        />

                        <div className="form-check form-switch">
                            <input
                                onChange={() => handleCheckboxChange('remolquePlacasLuzPlaca')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolquePlacasLuzPlaca"
                                name="Intercambio_remolquePlacasLuzPlaca"
                                value="1"
                                checked={checkedValues.remolquePlacasLuzPlaca}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolquePlacasLuzPlaca">
                                Luz de placa
                            </label>
                            <br />
                            <input
                                onChange={() => handleCheckboxChange('remolquePlacasPlaca')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolquePlacasPlaca"
                                name="Intercambio_remolquePlacasPlaca"
                                value="1"
                                checked={checkedValues.remolquePlacasPlaca}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolquePlacasPlaca">
                                Placa
                            </label>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-body">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Sello 1</h5>
                    </div>
                    <img
                        id="Intercambio_remolqueSello1Img"
                        className="img-thumbnail rounded mr-2 mb-2"
                        src={imageSrcSello1 || camara}
                        alt="Placeholder"
                        width="140"
                        height="140"
                    />
                    <canvas id="Intercambio_remolqueSello1FotoCanvas" hidden></canvas>
                    <div className="col-sm-10">
                        <input
                            id="Intercambio_remolqueSello1Foto"
                            name="Intercambio_remolqueSello1Foto"
                            type="file"
                            accept="image/*"
                            capture
                            onChange={(e) => {
                                handleImageUploadSello1(e);
                                handleImageChange('Intercambio_remolqueSello1FotoUrl', e);
                            }}
                            className="form-control"
                        />
                        <input
                            id="Intercambio_remolqueSello1FotoUrl"
                            name="Intercambio_remolqueSello1FotoUrl"
                            type="text"
                            className="form-control"
                            hidden
                        />
                        <input
                            id="Intercambio_Sello1Txt"
                            name="Intercambio_Sello1Txt"
                            type="text"
                            className="form-control"
                            placeholder="Sello 1"
                        />

                        <div className="form-check form-switch">
                            <input
                                onChange={() => handleCheckboxChange('remolqueSello1Sello')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolqueSello1Sello"
                                name="Intercambio_remolqueSello1Sello"
                                value="1"
                                checked={checkedValues.remolqueSello1Sello}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolqueSello1Sello">
                                Sello 1
                            </label>
                            <br />
                            <input
                                onChange={() => handleCheckboxChange('remolqueSello1Segurida')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolqueSello1Seguridad"
                                name="Intercambio_remolqueSello1Seguridad"
                                value="1"
                                checked={checkedValues.remolqueSello1Segurida}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolqueSello1Seguridad">
                                Sello alta seguridad
                            </label>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-body">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Sello 2</h5>
                    </div>
                    <img
                        id="Intercambio_remolqueSello2Img"
                        className="img-thumbnail rounded mr-2 mb-2"
                        src={imageSrcSello2 || camara}
                        alt="Placeholder"
                        width="140"
                        height="140"
                    />
                    <canvas id="Intercambio_remolqueSello2FotoCanvas" hidden></canvas>
                    <div className="col-sm-10">
                        <input
                            id="Intercambio_remolqueSello2Foto"
                            name="Intercambio_remolqueSello2Foto"
                            type="file"
                            accept="image/*"
                            capture
                            onChange={(e) => {
                                handleImageUploadSello2(e);
                                handleImageChange('Intercambio_remolqueSello2FotoUrl', e);
                            }}
                            className="form-control"
                        />
                        <input
                            id="Intercambio_remolqueSello2FotoUrl"
                            name="Intercambio_remolqueSello2FotoUrl"
                            type="text"
                            className="form-control"
                            hidden
                        />
                        <input
                            id="Intercambio_Sello2Txt"
                            name="Intercambio_Sello2Txt"
                            type="text"
                            className="form-control"
                            placeholder="Sello 2"
                        />
                        <div className="form-check form-switch">
                            <input
                                onChange={() => handleCheckboxChange('remolqueSello2Escotilla')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolqueSello2Escotilla"
                                name="Intercambio_remolqueSello2Escotilla"
                                value="1"
                                checked={checkedValues.remolqueSello2Escotilla}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolqueSello2Escotilla">
                                Escotilla
                            </label>
                            <br />
                            <input
                                onChange={() => handleCheckboxChange('remolqueSello2Sello')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolqueSello2Sello"
                                name="Intercambio_remolqueSello2Sello"
                                value="1"
                                checked={checkedValues.remolqueSello2Sello}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolqueSello2Sello">
                                Sello 2
                            </label>
                            <br />
                            <input
                                onChange={() => handleCheckboxChange('remolqueSello2Seguridad')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolqueSello2Seguridad"
                                name="Intercambio_remolqueSello2Seguridad"
                                value="1"
                                checked={checkedValues.remolqueSello2Seguridad}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolqueSello2Seguridad">
                                Sello alta seguridad
                            </label>
                            <br />
                            <input
                                onChange={() => handleCheckboxChange('remolqueSello2Vvtt')}
                                className="form-check-input"
                                type="checkbox"
                                id="Intercambio_remolqueSello2Vvtt"
                                name="Intercambio_remolqueSello2Vvtt"
                                value="1"
                                checked={checkedValues.remolqueSello2Vvtt}
                            />
                            <label className="form-check-label" htmlFor="Intercambio_remolqueSello2Vvtt">
                                VVT
                            </label>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <div className="card">
                <div className="card-body">
                    <div className="card-header">
                        <h5 className="card-title mb-0">Sello 3</h5>
                    </div>
                    <img
                        id="Intercambio_remolqueSello3Img"
                        className="img-thumbnail rounded mr-2 mb-2"
                        src={imageSrcSello3 || camara}
                        alt="Placeholder"
                        width="140"
                        height="140"
                    />
                    <canvas id="Intercambio_remolqueSello3FotoCanvas" hidden></canvas>
                    <div className="col-sm-10">
                        <input
                            id="Intercambio_remolqueSello3Foto"
                            name="Intercambio_remolqueSello3Foto"
                            type="file"
                            accept="image/*"
                            capture
                            onChange={(e) => {
                                handleImageUploadSello3(e);
                                handleImageChange('Intercambio_remolqueSello3FotoUrl', e);
                            }}
                            className="form-control"
                        />
                        <input
                            id="Intercambio_remolqueSello3FotoUrl"
                            name="Intercambio_remolqueSello3FotoUrl"
                            type="text"
                            className="form-control"
                            hidden
                        />
                        <input
                            id="Intercambio_Sello3Txt"
                            name="Intercambio_Sello3Txt"
                            type="text"
                            className="form-control"
                            placeholder="Sello 3"
                        />
                    </div>
                </div>
            </div>


            <footer className="footer">
                <div className="container-fluid">
                    <div className="row text-muted">
                        <div className="col-6 text-start">
                            <div className="progress">
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                                    Inicio
                                </div>
                                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Tractor
                                </div>
                                <div className="progress-bar" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Izquierda
                                </div>
                                <div className="progress-bar " role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Trasera
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    Derecha
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                                    20%
                                </div>
                                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "0%" }} aria-valuenow={0} aria-valuemin={0}>
                                </div>
                            </div>

                        </div>
                        <div className="col-6 text-end text-right">
                            <button onClick={handleSiguienteClick} id="siguienteEtapa" className="btn btn-success" disabled={!imagesSelected}>
                                Siguiente
                            </button>
                        </div>
                    </div>
                </div>
            </footer>
            </div>
        </div>
    );
};






export default Etapas4;