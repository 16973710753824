import React, { useState, useEffect, ChangeEvent } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import usuario from '../images/usuario.jpg';
import { FaPlus, FaBars } from 'react-icons/fa';
import camara from '../images/photos/photoCamera.png'
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { Etapa52 } from "../services/etapa1";


//Etapa funcionando al 100 %  , mejorando y sanitizando la salida de datos al momento de cambiar de etapa



type NewPageProps = {
    WrappedComponent: React.ComponentType<any>;
}

const Etapas5_2: React.FC<NewPageProps> = ({ WrappedComponent }) => {
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(true);

    const handleToggleMenu = () => {
        setMenuCollapsed(!menuCollapsed);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationTime');
        window.location.href = '/';
    };


    return (
        <div>
            <Navbar bg="dark" variant='dark' expand='lg' className='fixed-top'>
                <Navbar.Brand>
                    <img src={usuario} alt='User Logo' height='50' />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='navbar-nav' onClick={handleToggleMenu}>
                    <FaBars />
                </Navbar.Toggle>
                <Navbar.Collapse in={!menuCollapsed}>
                    <Nav>
                        <NavDropdown title="Usuario" id='user-dropdown'>
                            <NavDropdown.Item onClick={handleLogout}>Cerrar Sesión</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div style={{ paddingTop: '80px' }}>
                <div className='container-fluid' style={{ height: '100vh' }}>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='content' style={{ width: '90%', maxWidth: '100%', height: '100%', margin: '0 auto' }}>
                                <Etapa5_2 />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

};



const Etapa5_2: React.FC = () => {

    type CheckedValues = {
        [key: string]: boolean;
    };

    const [imageSrcChasisFrontalDerecho, setImageSrcFrontalDerecho] = useState('');
    const [imageSrcChasisTraseroDerecho, setImageSrcTraseroDerecho] = useState('');

    const [imagesData, setImagesData] = useState<{ [key: string]: File | null }>({
        Intercambio_remolqueChasisFrontalDerFotoUrl : null,
        Intercambio_remolqueChasisTraseroDerFotoUrl : null
       
    });

    const [imagesSelected, setImagesSelected] = useState(false);
    useEffect(() => {
        const allImagesSelected = Object.values(imagesData).every(image => image !== null);
        setImagesSelected(allImagesSelected);
    }, [imagesData]);

    const handleImageChange = (id: string, e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files[0]) {
            setImagesData((prevImagesData) => ({
                ...prevImagesData,
                [id]: files[0],
            }))
        }
    };



    const [checkedValues, setCheckedValues] = useState<CheckedValues>({
        remolqueChasisFrontalDerAmortiguador : false ,
        remolqueChasisFrontalDerBolsaAire : false ,
        remolqueChasisFrontalDerGavilan : false ,
        remolqueChasisFrontalDerMuelle : false ,
        remolqueChasisFrontalDerRotachamber : false,
        remolqueChasisTraseroDerAmortiguador : false ,
        remolqueChasisTraseroDerBolsaAire : false ,
        remolqueChasisTraseroDerGavilan : false ,
        remolqueChasisTraseroDerMuelle : false ,
        remolqueChasisTraseroDerRotachamber : false
    });

    const handleCheckboxChange = (checkboxName: string) => {
        setCheckedValues((prevValues) => ({
            ...prevValues,
            [checkboxName]: !prevValues[checkboxName],
        }));
    };


    const handleImageUploadChasisFrontalDerecho = (event: any) =>{
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcFrontalDerecho(imageUrl);
        }
    };

    const handleImageUploadChasisTraseroDerecho = (event : any) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrcTraseroDerecho(imageUrl);
        }
    };

    const handleSiguienteClick = async () => {
        const filenames : {[key : string]: string | null} = {};
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ?? '';
        const estatus = 12;

        for (const key in imagesData) {
            if (imagesData.hasOwnProperty(key)){
                const file = imagesData[key];
                if (file) {
                    const filename  = await uploadImageToFirebaseStorage(file);
                    filenames[key] = filename;

                }
            }
        }


        try {
            const data = {

            Intercambio_remolqueChasisFrontalDerFotoUrl : filenames.Intercambio_remolqueChasisFrontalDerFotoUrl ,
			Intercambio_remolqueChasisTraseroDerFotoUrl : filenames.Intercambio_remolqueChasisTraseroDerFotoUrl ,

			Intercambio_remolqueChasisFrontalDerAmortiguador : checkedValues.remolqueChasisFrontalDerAmortiguador,
			Intercambio_remolqueChasisFrontalDerBolsaAire : checkedValues.remolqueChasisFrontalDerBolsaAire,
			Intercambio_remolqueChasisFrontalDerGavilan : checkedValues.remolqueChasisFrontalDerGavilan ,
			Intercambio_remolqueChasisFrontalDerMuelle : checkedValues.remolqueChasisFrontalDerMuelle,
			Intercambio_remolqueChasisFrontalDerRotachamber : checkedValues.remolqueChasisFrontalDerRotachamber,

			Intercambio_remolqueChasisTraseroDerAmortiguador : checkedValues.remolqueChasisTraseroDerAmortiguador,
			Intercambio_remolqueChasisTraseroDerBolsaAire : checkedValues.remolqueChasisTraseroDerBolsaAire,
			Intercambio_remolqueChasisTraseroDerGavilan : checkedValues.remolqueChasisTraseroDerGavilan,
			Intercambio_remolqueChasisTraseroDerMuelle : checkedValues.remolqueChasisTraseroDerMuelle,
			Intercambio_remolqueChasisTraseroDerRotachamber : checkedValues.remolqueChasisTraseroDerRotachamber,
            estatus
            
            };
            console.log(data);
            const response = await Etapa52(id,data);
            setImageSrcFrontalDerecho('');
            setImageSrcTraseroDerecho('');
            window.location.href = `/Etapa5_3?id=${id}`;

        } catch (error:any) {
            console.error("Error en la respuesta de la api", error.message)
        }


    };

    



    const uploadImageToFirebaseStorage = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const storageRef = firebase.storage().ref();

            const timestamp = Date.now();
            const randomNumber = Math.floor(Math.random() * 1000);
            const uniqueFilename = `${timestamp}-${randomNumber}.${file.name.split('.').pop()}`;

            const imageRef = storageRef.child(`Intercambio/${uniqueFilename}`);

            imageRef
                .put(file)
                .then(() => {
                    resolve(uniqueFilename);
                })
                .catch((error) => {
                    console.error('Error al subir la imagen:', error);
                    reject(error);
                });
        });
    };

    return (
        <div className="container-fluid p-0">
            <h1 className="h3 mb-3">Remolque: Parte derecha</h1>
            <div className="row">
                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            Chasis frontal Derecho
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueChasisFrontalDerImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={ imageSrcChasisFrontalDerecho || camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueChasisFrontalDerFotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Chasis frontal Derecho</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueChasisFrontalDerFoto"
                                    name="Intercambio_remolqueChasisFrontalDerFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadChasisFrontalDerecho(e);
                                        handleImageChange('Intercambio_remolqueChasisFrontalDerFotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueChasisFrontalDerFotoUrl"
                                    name="Intercambio_remolqueChasisFrontalDerFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerAmortiguador')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerAmortiguador"
                                    name="Intercambio_remolqueChasisFrontalDerAmortiguador"
                                    checked={checkedValues.remolqueChasisFrontalDerAmortiguador}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerAmortiguador">
                                    Amortiguador
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerBolsaAire')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerBolsaAire"
                                    name="Intercambio_remolqueChasisFrontalDerBolsaAire"
                                    checked={checkedValues.remolqueChasisFrontalDerBolsaAire}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerBolsaAire">
                                    Bolsa de aire
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerGavilan')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerGavilan"
                                    name="Intercambio_remolqueChasisFrontalDerGavilan"
                                    checked={checkedValues.remolqueChasisFrontalDerGavilan}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerGavilan">
                                    Gavilan
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerMuelle')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerMuelle"
                                    name="Intercambio_remolqueChasisFrontalDerMuelle"
                                    checked={checkedValues.remolqueChasisFrontalDerMuelle}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerMuelle">
                                    Muelle
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisFrontalDerRotachamber')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisFrontalDerRotachamber"
                                    name="Intercambio_remolqueChasisFrontalDerRotachamber"
                                    checked={checkedValues.remolqueChasisFrontalDerRotachamber}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisFrontalDerRotachamber">
                                    Rotachamber
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-xl-6">
                    <div className="card">
                        <div className="card-header">
                            Chasis trasero Derecho
                        </div>
                        <div className="card-body">
                            <img
                                id="Intercambio_remolqueChasisTraseroDerImg"
                                className="img-thumbnail rounded mr-2 mb-2"
                                src={  imageSrcChasisTraseroDerecho || camara}
                                alt="Placeholder"
                                width="140"
                                height="140"
                            />
                            <canvas id="Intercambio_remolqueChasisTraseroDerFotoCanvas" hidden></canvas>
                            <div className="card-header">
                                <h5 className="card-title mb-0">Chasis Trasero Derecho</h5>
                            </div>
                            <div className="col-sm-10">
                                <input
                                    id="Intercambio_remolqueChasisTraseroDerFoto"
                                    name="Intercambio_remolqueChasisTraseroDerFoto"
                                    type="file"
                                    accept="image/*"
                                    capture
                                    onChange={(e) => {
                                        handleImageUploadChasisTraseroDerecho(e);
                                        handleImageChange('Intercambio_remolqueChasisTraseroDerFotoUrl', e);
                                    }}
                                    className="form-control"
                                />
                                <input
                                    id="Intercambio_remolqueChasisTraseroDerFotoUrl"
                                    name="Intercambio_remolqueChasisTraseroDerFotoUrl"
                                    type="text"
                                    className="form-control"
                                    hidden
                                />
                            </div>
                            <div className="form-check form-switch">
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerAmortiguador')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerAmortiguador"
                                    name="Intercambio_remolqueChasisTraseroDerAmortiguador"
                                    checked={checkedValues.remolqueChasisTraseroDerAmortiguador}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerAmortiguador">
                                    Amortiguador
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerBolsaAire')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerBolsaAire"
                                    name="Intercambio_remolqueChasisTraseroDerBolsaAire"
                                    checked={checkedValues.remolqueChasisTraseroDerBolsaAire}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerBolsaAire">
                                    Bolsa de aire
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerGavilan')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerGavilan"
                                    name="Intercambio_remolqueChasisTraseroDerGavilan"
                                    checked={checkedValues.remolqueChasisTraseroDerGavilan}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerGavilan">
                                    Gavilan
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerMuelle')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerMuelle"
                                    name="Intercambio_remolqueChasisTraseroDerMuelle"
                                    checked={checkedValues.remolqueChasisTraseroDerMuelle}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerMuelle">
                                    Muelle
                                </label>
                                <br />
                                <input
                                    onChange={() => handleCheckboxChange('remolqueChasisTraseroDerRotachamber')}
                                    className="form-check-input"
                                    type="checkbox"
                                    id="Intercambio_remolqueChasisTraseroDerRotachamber"
                                    name="Intercambio_remolqueChasisTraseroDerRotachamber"
                                    checked={checkedValues.remolqueChasisTraseroDerRotachamber}

                                />
                                <label className="form-check-label" htmlFor="Intercambio_remolqueChasisTraseroDerRotachamber">
                                    Rotachamber
                                </label>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
        <div className="container-fluid">
          <div className="row text-muted">
            <div className="col-6 text-start">
              <div className="progress">
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={16.6} aria-valuemin={0} aria-valuemax={100}>
                  Inicio
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Tractor
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Izquierda
                </div>
                <div className="progress-bar bg-success" role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Trasera
                </div>
                <div className="progress-bar " role="progressbar" style={{ width: "16.6%" }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}>
                  Derecha
                </div>
                <div className="progress-bar bg-warning" role="progressbar" style={{ width: "16.9%" }} aria-valuenow={0} aria-valuemin={0}>
                Firma
                </div>
              </div>
            </div>
            <div className="col-6 text-end text-right">
              <button onClick={handleSiguienteClick} id="siguienteEtapa" className="btn btn-success" disabled={!imagesSelected} >
                Siguiente
              </button>
            </div>
          </div>
        </div>
      </footer>
        </div>
    );
}




export default Etapas5_2;