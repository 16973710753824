const MAX_RETRIES = 3; 
const RETRY_DELAY = 1000; 

async function fetchWithRetry(url: string, options: RequestInit, maxRetries: number): Promise<Response> {
  let retries = 0;
  while (true) { 
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        return response;
      }
    } catch (error) {
      
    }
    if (retries >= maxRetries) {
      console.error('Max retries reached');
      break; 
    }
    await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
    retries++;
  }
  throw new Error('Couldn\'t establish connection');
}

export async function tablaIntercambios() {
  const apiUrl = 'https://huskyapp.dxxpress.net/api/main';
  const token = obtenerToken();

  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`,
    'idUsuario': '3727',
    'nombre': 'Intercambio',
  };

  while (true) { 
    try {
      const response = await fetchWithRetry(apiUrl, {
        method: 'GET',
        headers: headers,
      }, MAX_RETRIES);

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error:', error);
    }
    await new Promise(resolve => setTimeout(resolve, RETRY_DELAY)); 
  }
}

function obtenerToken() {
  return localStorage.getItem('token');
}
